<template>
  <div class="project-page">


    <div class="breadcrumbs hidden-sm-and-down">
      <router-link to="/" class="breadcrumbs-root-link">Opportunities</router-link>
      <v-icon>mdi-arrow-right-thin</v-icon>
      <span>{{ project.name }}</span>
    </div>

    <div class="breadcrumbs-mobile hidden-md-and-up">
      <router-link to="/" class="breadcrumbs-root-link">
        <v-icon>mdi-chevron-left</v-icon>
        All Opportunities
      </router-link>
    </div>

    <!-- Desktop template start -->
    <div class="project-page-main-section hidden-sm-and-down">


      <div class="project-page-gallery-holder">

        <div class="project-page-gallery-active-image-wrapper" v-if="activeImage"
             v-bind:style="{backgroundImage: `url(${activeImage.public_path})`}"><img
            v-bind:src="activeImage.public_path"
            v-bind:alt="activeImage.alt"
            v-on:click="galleryDialog=true; galleryActiveImage=activeImage"
            class="project-page-gallery-active-image">
        </div>

        <div class="project-page-gallery-small-images-row">

          <img v-bind:src="image.public_path" class="project-page-gallery-small-image"
               v-bind:class="{'active': image.is_active}" v-on:click="setActiveImage(image)" alt=""
               v-for="image in previewImages" v-bind:key="image.public_path">

          <div v-if="imageToShowCount" class="project-page-gallery-show-more"
               v-on:click="galleryDialog=true; galleryActiveImage=activeImage">
            {{ imageToShowCount }} more
          </div>

        </div>

      </div>
      <div class="project-page-project-info-holder">

        <div class="project-name">
          <div class="project-logo">
            <img v-if="project.logo_public_path" v-bind:src="project.logo_public_path" alt="">
          </div>
          {{ project.name }}
          <div class="project-badge badge-status " v-bind:class="badges[project.status.toString()]">
            <span v-if="project.status === 'in_progress'">In progress</span>
            <span v-if="project.status === 'exited'">Exited</span>
            <span v-if="project.status === 'coming'">Coming</span>
            <span v-if="project.status === 'invested'">Invested</span>
          </div>
        </div>
        <div style="margin-top: 16px; display: flex;">
          <div class="project-item-label-holder">

            <div class="project-item-label"
                 v-for="label in project.labels" v-bind:key="label.id">
              {{ label.label.name }}
            </div>

          </div>
          <div class="project-location">{{ project.location }}</div>
        </div>
        <div class="project-short-description" v-html="project.short_description"></div>


        <div class="project-properties-holder">

          <div class="project-properties-row">

            <div class="project-property">
              <div class="project-property-name">FUNDED</div>
              <div class="project-property-value">
                <!--                <v-icon v-if="project.currency">{{ project.currency.icon }}</v-icon>-->
                <!--                {{ project.amount_collected_pretty }}-->

                {{ progress_percent }} %
              </div>
            </div>

            <div class="project-property">
              <div class="project-property-name">INVESTORS</div>
              <div class="project-property-value">{{ project.investors_count }}</div>
            </div>

            <div class="project-property">
              <div class="project-property-name">IRR</div>
              <div class="project-property-value">{{ project.irr }} %</div>
            </div>

            <div class="project-property">
              <div class="project-property-name">TENOR</div>
              <div class="project-property-value">{{ project.tenor }}</div>
            </div>

          </div>

          <div class="project-properties-divider"></div>

          <div class="project-properties-row">

            <div class="project-property">
              <div class="project-property-name">TYPE</div>
              <div class="project-property-value">{{ project.type }}</div>
            </div>

            <div class="project-property">
              <div class="project-property-name">INSTRUMENT</div>
              <div class="project-property-value">{{ project.instrument_type }}</div>
            </div>

            <div class="project-property">
              <div class="project-property-name">GUARANTEE</div>
              <div class="project-property-value">{{ project.guarantee }}</div>
            </div>

            <div class="project-property">
              <div class="project-property-name">PURPOSE</div>
              <div class="project-property-value">{{ project.purpose }}</div>
            </div>

          </div>

        </div>

        <div class="project-progressbar-holder"
             v-if="project.status !== 'coming'"
             v-bind:class="{'unlimited-fundraising': project.amount_collected > project.minimum_amount_to_collect && project.minimum_amount_to_collect}">

          <div class="project-progressbar-value">
            <v-icon v-if="project.currency">{{ project.currency.icon }}</v-icon>
            {{ project.amount_collected_pretty }}
          </div>
          <div class="project-progressbar-progress" v-bind:style="{width: progress_percent + '%'}">
            <span v-if="progress_percent >= 20 && !project.minimum_amount_to_collect"><v-icon v-if="project.currency">{{
                project.currency.icon
              }}</v-icon> {{ project.amount_collected_pretty_short }}</span>
            <span
                v-if="project.amount_collected > project.minimum_amount_to_collect && project.minimum_amount_to_collect"
                class="progress-bar-unlimited-fundraising">UNLIMITED FUNDRAISING</span>
          </div>
          <div class="project-progressbar-bg"></div>

        </div>

        <div class="project-status-section ">

          <div v-if="project.status === 'in_progress'">
            <p class="project-progressbar-minimum-ticket">Minimum ticket:
              <v-icon v-if="project.currency">{{ project.currency.icon }}</v-icon>
              {{ project.minimum_ticket_pretty }}
            </p>
            <div class="project-page-actions-section">

              <button class="fancy-button invest-button" v-on:click="invest()" v-if="project.status === 'in_progress'">
                Invest
              </button>
              <button class="simple-button feeback-button" v-on:click="sendFeedback()">Feedback</button>

            </div>
          </div>

          <div v-if="project.status === 'invested'">
            Fundraising process finished. <br/>
            You can check similar projects
            <router-link to="/">here</router-link>
          </div>

          <div v-if="project.status === 'exited'">
            Fundraising process finished. <br/>
            You can check similar projects
            <router-link to="/">here</router-link>
          </div>


          <div v-if="project.status === 'coming'">
            <p class="project-progressbar-minimum-ticket">Minimum ticket:
              <v-icon v-if="project.currency">{{ project.currency.icon }}</v-icon>
              {{ project.minimum_ticket_pretty }}
            </p>
            The opportunity to invest in the project will be available on {{ project.start_date }}


            <div>

              <button class="fancy-button invest-button" v-on:click="interest()">
                Expression of Interest
              </button>
              <button class="simple-button feeback-button" v-on:click="sendFeedback()">Feedback</button>

            </div>

          </div>

        </div>

      </div>

    </div>
    <!-- Desktop template end -->

    <!-- Mobile template start -->
    <div class="project-page-main-section-mobile hidden-md-and-up">

      <div class="project-mobile-gallery-holder">
        <swiper class="project-mobile-gallery swiper" v-bind:options="swiperOptions">
          <swiper-slide v-for="image in previewImages" v-bind:key="image.public_path">

            <img v-bind:src="image.public_path" class="project-mobile-gallery-item">

          </swiper-slide>


        </swiper>

        <div class="project-mobile-badge-holder">

          <div class="project-badge badge-status " v-bind:class="badges[project.status.toString()]">
            <span v-if="project.status === 'in_progress'">In progress</span>
            <span v-if="project.status === 'exited'">Exited</span>
            <span v-if="project.status === 'coming'">Coming</span>
            <span v-if="project.status === 'invested'">Invested</span>
          </div>

        </div>

      </div>

      <div class="project-name">
        <div class="project-logo">
          <img v-bind:src="project.logo_public_path" alt="">
        </div>
        {{ project.name }}
      </div>

      <div class="project-item-label-holder">

        <div class="project-item-label"
             v-for="label in project.labels" v-bind:key="label.id">
          {{ label.label.name }}
        </div>

      </div>
      <div style="margin-top: 16px;">
        <div class="project-location">{{ project.location }}</div>
      </div>
      <div class="project-short-description" v-html="project.short_description"></div>

      <div class="project-progressbar-holder"
           v-bind:class="{'unlimited-fundraising': project.amount_collected > project.minimum_amount_to_collect && project.minimum_amount_to_collect}">

        <div class="project-progressbar-value">
          <v-icon v-if="project.currency">{{ project.currency.icon }}</v-icon>
          {{ project.amount_collected_pretty }}
        </div>
        <div class="project-progressbar-progress" v-bind:style="{width: progress_percent + '%'}">
          <span v-if="progress_percent >= 20 && !project.minimum_amount_to_collect"><v-icon v-if="project.currency">{{
              project.currency.icon
            }}</v-icon> {{ project.amount_collected_pretty_short }}</span>
          <span v-if="project.amount_collected > project.minimum_amount_to_collect && project.minimum_amount_to_collect"
                class="progress-bar-unlimited-fundraising">UNLIMITED FUNDRAISING</span>
        </div>
        <div class="project-progressbar-bg"></div>

      </div>

      <div class="project-progressbar-mobile-bottom-section">
        <p class="project-progressbar-minimum-ticket">Minimum ticket:
          <v-icon v-if="project.currency">{{ project.currency.icon }}</v-icon>
          {{ project.minimum_ticket_pretty }}
        </p>
        <p class="project-progressbar-minimum-target">Target:
          <v-icon v-if="project.currency">{{ project.currency.icon }}</v-icon>
          {{ project.amount_to_collect_pretty_with_k }}
        </p>

      </div>

      <div class="project-properties-holder">

        <div class="project-properties-row">

          <div class="project-property">
            <div class="project-property-name">FUNDED</div>
            <div class="project-property-value">
              <!--              <v-icon v-if="project.currency">{{ project.currency.icon }}</v-icon>-->
              <!--              {{ project.amount_to_collect_pretty }}-->
              {{ progress_percent }} %
            </div>
          </div>

          <div class="project-property">
            <div class="project-property-name">INVESTORS</div>
            <div class="project-property-value">{{ project.investors_count }}</div>
          </div>

          <div class="project-property">
            <div class="project-property-name">IRR</div>
            <div class="project-property-value">{{ project.irr }} %</div>
          </div>


        </div>

        <div class="project-properties-divider"></div>

        <div class="project-properties-row">

          <div class="project-property">
            <div class="project-property-name">TENOR</div>
            <div class="project-property-value">{{ project.tenor }}</div>
          </div>

          <div class="project-property">
            <div class="project-property-name">TYPE</div>
            <div class="project-property-value">{{ project.type }}</div>
          </div>

          <div class="project-property">
            <div class="project-property-name">INSTRUMENT</div>
            <div class="project-property-value">{{ project.instrument_type }}</div>
          </div>


        </div>

        <div class="project-properties-divider"></div>

        <div class="project-properties-row">

          <div class="project-property">
            <div class="project-property-name">GUARANTEE</div>
            <div class="project-property-value">{{ project.guarantee }}</div>
          </div>

          <div class="project-property">
            <div class="project-property-name">PURPOSE</div>
            <div class="project-property-value">{{ project.purpose }}</div>
          </div>


        </div>


      </div>


      <div style="margin-top: 28px;" class="text-center">

        <button class="fancy-button invest-button" v-if="project.status === 'in_progress'" v-on:click="invest()">
          Invest
        </button>
        <button class="simple-button feedback-button" v-on:click="sendFeedback()">Feedback</button>

      </div>

    </div>


    <!-- Mobile template end -->

    <div class="tabs-mobile hidden-md-and-up">

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">

          <div class="tabs-mobile-menu">
            <span v-bind="attrs"
                  v-on="on">{{ activeTabVerboseName }}
            </span>
          </div>

        </template>
        <div class="tabs-mobile-menu-dropdown-holder">
          <div class="tabs-mobile-menu-dropdown-content">
            <div class="tabs-mobile-menu-dropdown-item" v-bind:class="{'active': activeTab === 'opportunity'}"
                 v-on:click="setActiveTab('opportunity', 'Opportunity')">
              Opportunity
            </div>
            <div class="tabs-mobile-menu-dropdown-item" v-bind:class="{'active': activeTab === 'instrument'}"
                 v-on:click="setActiveTab('instrument', 'Instrument')">
              Instrument
            </div>
            <div class="tabs-mobile-menu-dropdown-item" v-bind:class="{'active': activeTab === 'documents'}"
                 v-on:click="setActiveTab('documents', 'Documents')"
                 v-if="project.attachments">
              Documents
            </div>

            <div class="tabs-mobile-menu-dropdown-item" v-bind:class="{'active': activeTab === 'updates'}"
                 v-on:click="setActiveTab('updates', 'Updates')">
              Updates
            </div>

            <div class="tabs-mobile-menu-dropdown-item" v-bind:class="{'active': activeTab === 'private_forum'}"
                 v-on:click="setActiveTab('private_forum', 'Private Forum')">
              Private Forum
            </div>

          </div>

        </div>
      </v-menu>

    </div>

    <div class="tabs hidden-sm-and-down">

      <div class="tab" v-bind:class="{'active': activeTab === 'opportunity'}"
           v-on:click="setActiveTab('opportunity', 'Opportunity')">
        Opportunity
      </div>

      <div class="tab" v-bind:class="{'active': activeTab === 'instrument'}"
           v-on:click="setActiveTab('instrument', 'Instrument')"
           v-if="project.instrument">
        Instrument
      </div>

      <div class="tab" v-bind:class="{'active': activeTab === 'documents'}"
           v-on:click="setActiveTab('documents', 'Documents')"
           v-if="project.attachments">
        Documents
      </div>

      <div class="tab" v-bind:class="{'active': activeTab === 'updates'}"
           v-on:click="setActiveTab('updates', 'Updates')">
        Updates
      </div>

      <div class="tab" v-bind:class="{'active': activeTab === 'private_forum'}"
           v-on:click="setActiveTab('private_forum', 'Private Forum')">
        Private Forum
      </div>

    </div>

    <!--  Opportunity  -->
    <div class="tab-content" v-show="activeTab === 'opportunity'">

      <div class="project-page-content-holder">
        <div class="project-page-content-sidebar hidden-sm-and-down flex-1" v-if="contentTitles.length">

          <div v-for="item in contentTitles" v-bind:key="item.name" class="project-page-content-sidebar-title">

            <a v-bind:href="'#'+item.id">{{ item.name }}</a>

            <div v-for="subtitle in item.subtitles" v-bind:key="subtitle.name"
                 class="project-page-content-sidebar-subtitle">
              <a v-bind:href="'#'+subtitle.id">{{ subtitle.name }}</a>
            </div>

          </div>

        </div>

        <div v-html="content" class="project-page-content flex-3">

        </div>
      </div>

    </div>

    <!--  Instrument  -->
    <div class="tab-content" v-show="activeTab === 'instrument'" v-if="project.instrument">

      <div class="black-table">

        <div class="black-table-row" v-if="project.instrument.name">

          <div class="black-table-column flex-1">
            Name
          </div>
          <div class="black-table-column flex-3">
            {{ project.instrument.name }}
          </div>

        </div>

        <div class="black-table-row" v-if="project.instrument.issuer">

          <div class="black-table-column flex-1">
            Issuer
          </div>
          <div class="black-table-column flex-3">
            {{ project.instrument.issuer }}
          </div>

        </div>

        <div class="black-table-row" v-if="project.instrument.tenor">

          <div class="black-table-column flex-1">
            Tenor
          </div>
          <div class="black-table-column flex-3">
            {{ project.instrument.tenor }}
          </div>

        </div>

        <div class="black-table-row" v-if="project.instrument.isin">

          <div class="black-table-column flex-1">
            ISIN
          </div>
          <div class="black-table-column flex-3">
            {{ project.instrument.isin }}
          </div>

        </div>

        <div class="black-table-row" v-if="project.instrument.minimum_ticket">

          <div class="black-table-column flex-1">
            Minimum ticket size
          </div>
          <div class="black-table-column flex-3">
            {{ project.instrument.minimum_ticket }}
          </div>

        </div>

        <div class="black-table-row" v-if="project.instrument.security">

          <div class="black-table-column flex-1">
            Security
          </div>
          <div class="black-table-column flex-3">
            {{ project.instrument.security }}
          </div>

        </div>

        <div class="black-table-row" v-if="project.instrument.fees">

          <div class="black-table-column flex-1">
            Fees
          </div>
          <div class="black-table-column flex-3">
            {{ project.instrument.fees }}
          </div>

        </div>

        <div class="black-table-row" v-if="project.instrument.target_irr">

          <div class="black-table-column flex-1">
            Target IRR
          </div>
          <div class="black-table-column flex-3">
            {{ project.instrument.target_irr }}
          </div>

        </div>

        <div class="black-table-row" v-if="project.instrument.coupon">

          <div class="black-table-column flex-1">
            Coupon
          </div>
          <div class="black-table-column flex-3">
            {{ project.instrument.coupon }}
          </div>

        </div>

        <div class="black-table-row" v-if="project.instrument.arranger">

          <div class="black-table-column flex-1">
            Arranger
          </div>
          <div class="black-table-column flex-3">
            {{ project.instrument.arranger }}
          </div>

        </div>

        <div class="black-table-row" v-if="project.instrument.paying_agent">

          <div class="black-table-column flex-1">
            Paying agent
          </div>
          <div class="black-table-column flex-3">
            {{ project.instrument.paying_agent }}
          </div>

        </div>

        <div class="black-table-row" v-if="project.instrument.clearing">

          <div class="black-table-column flex-1">
            Clearing
          </div>
          <div class="black-table-column flex-3">
            {{ project.instrument.clearing }}
          </div>

        </div>

        <div class="black-table-row" v-if="project.instrument.valuation_dates">

          <div class="black-table-column flex-1">
            Valuation dates
          </div>
          <div class="black-table-column flex-3">
            {{ project.instrument.valuation_dates }}
          </div>

        </div>

        <div class="black-table-row" v-if="project.instrument.liquidity">

          <div class="black-table-column flex-1">
            Liquidity
          </div>
          <div class="black-table-column flex-3">
            {{ project.instrument.liquidity }}
          </div>

        </div>

        <div class="black-table-row" v-if="project.instrument.rebalancing_and_calculation_agent">

          <div class="black-table-column flex-1">
            Rebalancing and Calculation Agent
          </div>
          <div class="black-table-column flex-3">
            {{ project.instrument.rebalancing_and_calculation_agent }}
          </div>

        </div>


      </div>

      <div style="margin-top: 36px; padding-bottom: 20px;" class="text-center" v-if="project.status === 'in_progress'">

        <button class="fancy-button invest-button" v-on:click="invest()">
          Invest
        </button>
        <button class="simple-button feedback-button" v-on:click="sendFeedback()">Feedback</button>

      </div>

      <div style="margin-top: 36px; padding-bottom: 20px;" class="text-center" v-if="project.status === 'coming'">

        <button class="fancy-button invest-button" v-on:click="interest()">
          Expression of Interest
        </button>
        <button class="simple-button feedback-button" v-on:click="sendFeedback()">Feedback</button>

      </div>


    </div>

    <!-- Documents   -->
    <div class="tab-content" v-show="activeTab === 'documents'">
      <div v-if="project.attachments.length">

        <div v-for="item in project.attachments" v-bind:key="item.id" class="project-attachment">

          <v-icon class="project-attachment-icon" v-show="item.filename.indexOf('.pdf') !== -1">mdi-file-pdf-box
          </v-icon>
          <v-icon class="project-attachment-icon" v-show="item.filename.indexOf('.xlsx') !== -1">mdi-microsoft-excel
          </v-icon>

          <div>
            <div>

              <a v-bind:href="item.public_path" target="_blank" class="project-attachment-filename">
                {{ item.filename }}
              </a>

              <span class="project-attachment-date">{{ item.date_pretty }}</span>

            </div>
            <div class="project-attachment-description">{{ item.description }}</div>
          </div>
        </div>

      </div>
    </div>

    <!-- Updates   -->
    <div class="tab-content" v-show="activeTab === 'updates'">

      <div v-if="project.updates.length">

        <div v-for="item in project.updates" v-bind:key="item.id" class="project-update">

          <div class="project-update-title">
            {{ item.title }}

            <span class="project-update-date">{{ item.date_pretty }}</span>
          </div>

          <div class="project-update-body flex-column flex-sm-row">


            <img class="project-update-thumbnail" v-if="item.thumbnail_public_path"
                 v-bind:src="item.thumbnail_public_path" alt="">


            <div class="project-update-description">
              {{ item.short_description }}
            </div>

          </div>

          <router-link v-if="item.content" class="simple-button read-more-button"
                       v-bind:to="{'path': '/project/' + project.id + '/update/' + item.id}">Read more
          </router-link>
        </div>

      </div>

      <div v-if="!project.updates.length">
        <h4>Nothing Happened Yet</h4>
      </div>

    </div>

    <!-- Private Forum   -->
    <div class="tab-content" v-show="activeTab === 'private_forum'">

      <div v-if="project.messages_pretty.length">

        <div v-for="item in project.messages_pretty" v-bind:key="item.id">

          <div class="project-message">

            <div class="project-message-header">

              <v-icon class="project-message-profile">mdi-account</v-icon>
              <div>
                <div class="project-message-username">{{ user.first_name }}</div>
                <div class="project-message-date">{{ item.date_pretty }}</div>
              </div>

            </div>

            <div v-html="item.text">

            </div>
          </div>

          <div class="project-message-reply-holder">

            <div v-for="reply in item.replies" v-bind:key="reply.id">
              <div class="project-message">

                <div class="project-message-header">

                  <v-icon class="project-message-profile">mdi-account</v-icon>
                  <div>
                    <div class="project-message-username">System</div>
                    <div class="project-message-date">{{ item.date_pretty }}</div>
                  </div>

                </div>

                <div v-html="reply.text">

                </div>

              </div>
            </div>

          </div>

        </div>

      </div>


      <div class="user-message-textarea-holder">
        <textarea class="user-message-textarea" v-model="userMessage" placeholder="Write your message here"></textarea>
        <button class="fancy-button float-right" v-on:click="sendMessage()">Send</button>

      </div>

    </div>


    <v-dialog
        v-model="galleryDialog"
        fullscreen
        hide-overlay
    >
      <div class="gallery-dialog-content">

        <div class="gallery-dialog-sidebar">

          <img v-bind:src="image.public_path" class="project-page-gallery-small-image"
               v-bind:class="{'active': image.is_active}" v-on:click="setGalleryActiveImage(image)" alt=""
               v-for="image in project.images" v-bind:key="image.public_path">

        </div>

        <div class="galley-dialog-main-image-holder">
          <img v-if="galleryActiveImage" v-bind:src="galleryActiveImage.public_path" v-bind:alt="galleryActiveImage.alt"
               class="galley-dialog-main-image">
        </div>


        <v-icon v-on:click="galleryDialog=false" class="gallery-dialog-close-button">mdi-close</v-icon>


      </div>
    </v-dialog>

    <v-dialog
        width="470"
        v-model="feedbackDialog"
    >
      <div class="feedback-dialog-content">

        <h2 class="feedback-dialog-title">Feedback</h2>
        <p>Please leave your opinion about this investment project</p>

        <div class="user-message-textarea-holder">
          <textarea class="user-message-textarea" v-model="userMessage"
                    placeholder="Write your message here"></textarea>
          <button class="fancy-button float-left" v-on:click="sendMessage()">Send</button>

        </div>


        <v-icon v-on:click="feedbackDialog=false" class="feedback-dialog-close-button">mdi-close</v-icon>


      </div>
    </v-dialog>

  </div>
</template>

<style lang="scss">

.project-page {

  .project-page-content-sidebar {
    position: sticky;
    position: -webkit-sticky;
    z-index: 1;
    display: block;
    top: 0;
    height: 100%;
    padding-right: 24px;
    box-sizing: border-box;
    padding-top: 0;
    transition: padding 0.3s linear;
  }


  .project-item-label-holder {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .project-item-label {
    font-size: 18.006px;
    line-height: 26px;
    //padding: 5px 15px;
    border: 1px solid #fff;
    border-radius: 5px;
    margin: 5px;
    max-height: 26px;
    padding: 0 16px;
  }


  .project-logo {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    float: left;
    margin-right: 10px;

    img {
      width: 30px;
      height: 30px;
    }

  }

  .breadcrumbs {
    margin-bottom: 19px;

    a {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 18px;
      /* or 74% */

      text-decoration-line: underline;

      color: #FFFFFF;
    }

    i {
      margin-left: 15px;
      margin-right: 15px;
    }

    span {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 18px;
    }
  }

  .project-badge {
    height: 27px;
    width: 94px;
    border-radius: 5px;
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 27px;
    margin-top: 12px;
    margin-left: 12px;
    display: inline-block;
    position: relative;
    top: -7px;
    /* identical to box height, or 133% */

    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;

    color: #FFFFFF;

    &.badge-white {
      color: #000;
    }

  }

  padding-bottom: 40px;

  .project-page-content-holder {
    display: flex;
    overflow: inherit;
  }

  .project-page-content {
    h2 {
      margin-bottom: 10px;
    }
  }

  .project-page-content-sidebar-title {

    a {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 30px;

      text-transform: uppercase;

      color: #FFFFFF;
    }
  }

  .project-page-content-sidebar-subtitle {
    padding-left: 24px;
    margin: 4px 0;

    a {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 30px;
      /* identical to box height, or 167% */

      text-transform: uppercase;

      color: #FFFFFF;
    }

  }

  .project-page-main-section {
    display: flex;
    //max-width: 1200px;
  }

  .project-status-section {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;

    color: #FFFFFF;
    text-align: left;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  .project-progressbar-minimum-ticket {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18.006px;
    line-height: 25px;

    color: #FFFFFF;
    text-align: left;
  }

  .project-page-actions-section {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;

    button {
      width: 40%;
    }
  }

  .project-page-gallery-holder {
    flex: 1;
    width: 50%;
    padding-right: 40px;
  }

  .project-page-project-info-holder {
    flex: 1;
  }

  .project-page-gallery-small-images-row {
    display: flex;
    justify-content: space-between;
  }

  .project-page-gallery-active-image-wrapper {
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 15px;
    height: 390px;
    width: 100%;
    background-size: cover;
  }

  .project-page-gallery-active-image {
    width: 100%;
    height: 100%;
    max-height: 390px;
    max-width: 100%;
    object-fit: contain;
    flex: none;
    cursor: pointer;
    border-radius: 5px;
    display: none;
  }


  .project-page-gallery-small-image {
    //width: 170px;
    //height: 129px;
    max-width: 23%;
    flex-grow: 1;
    cursor: pointer;

    border: 1px solid transparent;
    border-radius: 5px;

    &:hover {
      border: 1px solid #fff;
    }

    &.active {
      border: 1px solid #fff;
    }
  }

  .project-page-gallery-show-more {
    //width: 170px;
    max-width: 23%;
    flex-grow: 1;
    //height: 129px;
    border: 1px solid #fff;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    box-sizing: border-box;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .project-name {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 30px;
    color: #FFFFFF;
  }

  .project-location {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18.006px;
    line-height: 25px;
    color: #FFFFFF;
    padding-top: 6px;
    margin-left: 16px;
  }

  .project-short-description {
    margin-top: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18.006px;
    line-height: 25px;
    min-height: 75px;
    /* or 138% */


    color: #FFFFFF;
  }

  .project-properties-holder {
    width: 550px;
  }

  .project-properties-divider {
    width: 100%;
    height: 1px;
    background: #fff;
  }

  .project-property {

    display: inline-block;

    padding: 8px;
    box-sizing: border-box;
    width: 134px;
    position: relative;

    &:last-child:after {
      display: none;
    }

    &:after {
      content: '';
      height: 48px;
      width: 1px;
      display: inline-block;
      background: #fff;
      position: absolute;
      right: 0;
      top: 13px;

    }

    .project-property-name {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;

      text-align: center;
      text-transform: uppercase;
    }

    .project-property-value {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      white-space: nowrap;

      text-align: center;
      text-transform: uppercase;
      text-overflow: ellipsis;
      overflow: hidden;
    }

  }

  .project-progressbar-holder {
    position: relative;
    //width: 550px;
    width: 100%;
    height: 25px;
    margin-top: 55px;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 30px;

    &.unlimited-fundraising {
      .project-progressbar-progress {
        width: 100% !important;
      }

      color: #fff;

      span.progress-bar-unlimited-fundraising {
        font-weight: bold;
        font-size: 13px;
      }
    }

    .project-progressbar-bg {
      position: absolute;
      top: 0;
      left: 0;
      background: #fff;
      width: 100%;
      height: 25px;
    }

    .project-progressbar-progress {
      height: 25px;
      background: #35A153;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      text-align: center;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
      text-transform: uppercase;

      i {
        font-size: 14px;
        position: relative;
        top: -2px;
      }
    }

    .project-progressbar-value {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 25px;

      text-align: center;
      text-transform: uppercase;

      color: #FFFFFF;
    }

  }


  .tabs {
    text-align: left;
    margin-top: 66px;

    position: sticky;
    top: 0;
    background: #382040;
    z-index: 2;

    &:after {
      content: '';
      width: 200vw;
      display: block;
      background: #fff;
      height: 1px;
      margin-left: -100vw;
    }
  }

  .tab {
    display: inline-block;
    font-family: Montserrat;
    font-style: normal;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height, or 125% */
    padding: 20px 22px;

    cursor: pointer;


    color: #FFFFFF;
    border-bottom: 5px solid transparent;

    &:hover {
      opacity: .7;
    }

    &.active {
      border-bottom: 5px solid #fff;
      font-weight: bold;
    }
  }

  .tab-content {
    padding-top: 29px;

    img {
      max-width: 100%;
      overflow: hidden;
    }
  }

  .black-table-column:first-child {
    font-weight: bold;
    font-size: 18px;
  }

  .invest-button {

    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 16px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    /* identical to box height, or 83% */

    text-align: center;
  }

  .project-attachment {
    text-align: left;
    display: flex;
    margin-bottom: 50px;
  }

  .project-attachment-filename {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height, or 125% */

    text-decoration-line: underline;

    color: #FFFFFF;
  }

  .project-attachment-description {
    margin-top: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    /* or 167% */


    color: #FFFFFF;
  }

  .project-attachment-date {

    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    margin-left: 20px;
    display: inline-block;
  }

  .project-attachment-icon {
    margin-right: 32px;
    font-size: 64px;
  }

  .project-update {
    //width: 900px;
    margin: 0 auto 100px;
  }

  .project-update-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;

    text-transform: uppercase;
    text-align: left;

    color: #FFFFFF;
    margin-bottom: 10px;
  }

  .read-more-button {
    float: right;
    color: #fff;
  }

  .project-update-body {
    display: flex;
  }

  .project-update-description {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    /* or 167% */
    color: #FFFFFF;
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
  }

  .project-update-thumbnail {
    max-width: 50%;
    max-height: 350px;

    margin: 0 auto 16px;
  }

  .project-update-date {
    float: right;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
  }


  .project-message-reply-holder {
    padding-left: 150px;
  }

  .project-message {
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 38px;
    text-align: left;
  }

  .project-message-header {
    display: flex;
    margin-bottom: 10px;
  }

  .project-message-profile {
    font-size: 50px;
    margin-right: 25px;
  }

  .project-message-username {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
    text-align: left;
  }

  .project-message-date {

    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    /* identical to box height, or 214% */
    color: #FFFFFF;
  }


}

.user-message-textarea-holder {
  //width: 900px;
  margin: 130px auto 0;
  overflow: hidden;
}

.user-message-textarea {
  background: rgba(196, 196, 196, 0.2);
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 5px;
  display: block;
  width: 100%;
  margin-bottom: 25px;
  padding: 24px 30px;
  outline: none;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;

  color: #fff;
}


.gallery-dialog-content {
  background: #382040;
  height: 100%;
  display: flex;

  .galley-dialog-main-image-holder {
    flex: 1
  }

  .galley-dialog-main-image {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .gallery-dialog-sidebar {
    padding-top: 20px;
    width: 100px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #fff;

    img {
      max-width: 36px;
      margin: 0 auto 12px;
      cursor: pointer;
      border: 1px solid transparent;


      &:hover {
        border: 1px solid #fff;
      }

      &.active {
        border: 1px solid #fff;
      }
    }
  }

  .gallery-dialog-close-button {
    position: absolute;
    right: 16px;
    top: 16px;
    opacity: .7;

    &:hover {
      opacity: 1;
    }
  }

}

.feedback-dialog-content {
  background: #382040;
  padding: 15px 20px;
  text-align: left;
  position: relative;

  .feedback-dialog-close-button {
    position: absolute;
    right: 16px;
    top: 16px;
    opacity: .7;

    &:hover {
      opacity: 1;
    }
  }

  .user-message-textarea-holder {
    margin-top: 18px;
  }
}


@media screen and (max-width: 600px) {

  .project-page {

    .project-message-reply-holder {
      padding-left: 40px;
    }

    .black-table {
      .black-table-row {
        flex-direction: column;
      }

      .black-table-column:nth-child(2) {
        padding-left: 40px;
      }
    }


    .breadcrumbs-mobile {
      width: 156px;
      font-size: 14px;
      line-height: 30px;
      background: rgba(255, 255, 255, 0.7);
      border-radius: 2px;
      margin-bottom: 20px;

      a {
        color: #414041;
        text-decoration: none;
      }

      i {
        color: #414041;
      }
    }

    .project-page-main-section-mobile {
      text-align: center;

      .project-name {
        text-align: left;
        margin-top: 30px;
      }

      .project-item-label-holder {
        margin-top: 10px;
        justify-content: center;
      }

      .project-mobile-gallery-holder {
        height: 256px;
        position: relative;
      }

      .project-mobile-gallery {
        position: absolute;
        top: 0;
        left: -12px;
        width: 100vw;
        height: 100%;
      }

      //.swiper-slide {
      //
      //  width: 90% !important;
      //  padding: 0 5px;
      //}

      .project-mobile-badge-holder {
        position: absolute;
        bottom: -16px;
        z-index: 1;
        display: flex;

        width: 100%;
        justify-content: center;

        .project-badge {
          font-size: 12px;
          line-height: 26px;
        }

      }

      .project-mobile-gallery-item {
        height: 243px;
        border-radius: 5px;
        //width: 325px;
        width: 100%;
        margin-top: 6px;

      }

      .swiper-slide.swiper-slide-active {
        .project-mobile-gallery-item {
          height: 256px;
          margin-top: 0;
          transition: margin-top .3s linear;
          will-change: height, margin-top;
        }
      }

      .invest-button {
        width: 155px;
        height: 46px;
        border-radius: 5px;
      }

      .feedback-button {
        width: 155px;
        height: 46px;
        border-radius: 5px;
      }

      .project-progressbar-holder {
        margin-top: 20px;
        margin-bottom: 5px;
      }

      .project-properties-holder {
        width: 100%;
      }

      .project-property {
        width: 116px;
      }

      .project-property .project-property-name {
        font-size: 14px;
        line-height: 25px;
      }

      .project-property .project-property-value {
        font-size: 14px;
        line-height: 25px;
      }


      .project-progressbar-mobile-bottom-section {

        display: flex;
        justify-content: space-between;

        i {
          font-size: 16px;
        }

        .project-progressbar-minimum-ticket {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 25px;
          text-align: center;
        }

        .project-progressbar-minimum-target {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 25px;
          text-align: center;
        }
      }

    }


    .tabs-mobile-menu {
      padding: 8px 24px;
      margin: 38px auto;
      margin-left: -12px;
      width: 100vw;
      height: 43px;
      background: #614C6B;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 30px;
      /* identical to box height, or 167% */


      color: #FFFFFF;

    }


  }

  .tabs-mobile-menu-dropdown-item {
    background: #14191D;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 42px;
    padding: 8px 20px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;

    &.active {
      background: #43474A;
      border-radius: 0px;
    }

    &:hover {
      background: #43474A;
      border-radius: 0px;
    }
  }

}

</style>

<script>


import projectRepository from "@/repositories/projectRepository";
import numbersFormatter from "@/helpers/numbersFormatter";
import userRepository from "@/repositories/userRepository";

export default {
  name: 'ProjectPage',

  data: () => ({
    project: {
      status: '',
      images: [],
      instrument: {},
      attachments: [],
      updates: [],
      messages_pretty: [],
      labels: []
    },
    activeImage: null,
    content: '',
    activeTab: 'opportunity',
    activeTabVerboseName: 'Opportunity',
    userMessage: '',
    progress_percent: 0,
    previewImages: [],
    galleryDialog: false,
    galleryActiveImage: null,
    imageToShowCount: 0,
    feedbackDialog: false,
    contentTitles: [],
    badges: {
      'coming': 'badge-white',
      'in_progress': 'badge-blue',
      'invested': 'badge-green',
      'exited': 'badge-red'
    },
    swiperOptions: {
      centeredSlides: true,
      slidesPerView: 1.1,
      spaceBetween: 10,
    }
  }),
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {

    handleScroll() {
      // Any code to be executed when the window is scrolled

      var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
      if (scrollTop >= document.getElementsByClassName("tab-content")[0].offsetTop) {
        document.getElementsByClassName("project-page-content-sidebar")[0].style.paddingTop = "90px";
      } else {
        document.getElementsByClassName("project-page-content-sidebar")[0].style.paddingTop = "0px";
      }

    },

    sendFeedback() {
      this.feedbackDialog = true;
    },

    setActiveTab(tab, verboseName) {

      this.activeTab = tab;
      this.activeTabVerboseName = verboseName;
    },

    setActiveImage(image) {

      this.previewImages.forEach((item) => {
        item.is_active = false;
      })

      image.is_active = true;

      this.activeImage = image
    },

    setGalleryActiveImage(image) {

      this.project.images.forEach((item) => {
        item.is_active = false;
      })

      image.is_active = true;

      this.galleryActiveImage = image
    },

    invest() {

      projectRepository.interest(this.project.id, {interest: true}).then((response) => {

        console.log('response', response);

        let order = response.data

        this.$router.push('/order/' + order.id)

      })

    },

    interest() {

      projectRepository.interest(this.project.id, {interest: true}).then((response) => {

        console.log('response', response);

        let order = response.data

        this.$router.push('/interest/' + order.id)

      })

    },

    sendMessage() {

      projectRepository.sendMessage(this.project.id, {text: this.userMessage}).then(() => {

        this.feedbackDialog = false;
        this.userMessage = '';
        this.getData();
      })

    },

    formatMessages() {

      this.project.messages_pretty = this.project.messages.map((item) => {

        let date = new Date(item.created).toISOString().split('T')[0].split('-').join('.')
        let timePieces = new Date(item.created).toISOString().split('T')[1].split(':')
        let time = timePieces[0] + ':' + timePieces[1]

        item.date_pretty = date + ' ' + time

        return item
      })

    },

    async getUser() {
      return userRepository.getMe().then((response) => {
        this.user = response.data.results[0];

        console.log('user', this.user)
      })
    },

    getData() {

      projectRepository.getById(this.$route.params.id).then((response) => {

        this.project = response.data

        this.project.amount_collected_pretty = numbersFormatter.numberWithSpaces(this.project.amount_collected)
        this.project.amount_to_collect_pretty = numbersFormatter.numberWithSpaces(this.project.amount_to_collect)
        this.project.amount_to_collect_pretty_with_k = numbersFormatter.numberWithK(this.project.amount_to_collect)
        this.project.minimum_ticket_pretty = numbersFormatter.numberWithSpaces(this.project.minimum_ticket)

        this.project.amount_collected_pretty_short = numbersFormatter.numberWithK(this.project.amount_collected)

        this.progress_percent = Math.floor(this.project.amount_collected / (this.project.amount_to_collect / 100))

        this.project.attachments = this.project.attachments.map((item) => {

          item.date_pretty = new Date(item.created).toISOString().split('T')[0].split('-').join('.')

          return item
        })

        this.project.updates = this.project.updates.map((item) => {

          item.date_pretty = item.date.split('-').join('.')

          return item
        })

        this.formatMessages();

        if (this.project.images.length) {
          this.activeImage = this.project.images[0]
          this.project.images[0].is_active = true
          this.previewImages = this.project.images.filter((item, index) => {
            return index < 3
          });
          this.imageToShowCount = this.project.images.length - this.previewImages.length
        }

        console.log('project', this.project)

      })


      projectRepository.getContentById(this.$route.params.id).then((response) => {

        this.content = response.data

        setTimeout(() => {

          this.createContentSidebar()

        }, 0)


      })

    },

    createContentSidebar() {

      this.contentTitles = []

      let content = document.querySelector('.project-page-content')

      if (content) {

        var h1_items = content.querySelectorAll('h1')

        h1_items.forEach((item) => {

          let h2_items = content.querySelectorAll('[data-parent-id="' + item.id + '"]')

          let result = {
            name: item.textContent,
            id: item.id,
            subtitles: []
          }

          h2_items.forEach((h2_item) => {

            result.subtitles.push({
              id: h2_item.id,
              name: h2_item.textContent
            })

          })

          this.contentTitles.push(result)
        })

        setTimeout(() => {

          document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
              e.preventDefault();

              document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
              });
            });
          });

        }, 0)


      }

    }

  },

  async mounted() {

    await this.getUser();
    this.getData();

  }

}
</script>
