<template>
  <div class="login" style="width: 600px; margin: 0 auto;">
    <h1>You have been logged out</h1>
    <div>

      <p>Go to
        <router-link to="/login">Log In</router-link>
      </p>

    </div>
  </div>
</template>


<script>

import cookieService from "@/services/cookieService";

export default {
  name: 'LogoutPage',

  data: () => ({}),

  methods: {},

  mounted() {

    cookieService.deleteCookie('access')
    cookieService.deleteCookie('refresh')

  }
}
</script>
