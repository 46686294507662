<template>
  <div class="login">

    <img alt="Marscapital" src="../assets/logo_sis.png" class="header-logo">

    <div class="display-flex flex-space-between login-holder">
      <div class="login-form-wrapper flex-1">
        <div class="login-form-wrapper-inner-holder">
          <h1 class="text-center">Log in</h1>
          <div>

            <v-form
                ref="form"
                v-model="valid"
                lazy-validation

                v-if="!twoFactorCheck"
            >
              <v-text-field
                  v-model="email"
                  label="Email"
                  :rules="emailRules"
                  required
              ></v-text-field>

              <v-text-field

                  v-model="password"
                  label="Password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  :rules="passwordRules"
                  required
              ></v-text-field>

              <div class="forgot-password-line">
                <router-link to="/forgot-password"> Forgot your password?</router-link>
              </div>

              <v-btn v-on:click="login()"
                     class="fancy-button login-button"

              >Login
              </v-btn>

              <div v-if="errorMessage" style="color: red; margin-top: 8px;">
                {{ errorMessage }}
              </div>

            </v-form>

            <div v-if="twoFactorCheck">

              <v-text-field
                  v-model="securityCode"
                  label="Security Code"
                  v-on:keyup="validateCode()"
                  v-on:blur="validateCode()"
              ></v-text-field>

              <div v-if="securityCodeErrorMessage" style="color: red; margin-top: 16px">
                {{ securityCodeErrorMessage }}
              </div>


            </div>

            <div style="margin-top: 24px;" class="form-bottom-text">
              <div>
                Do not have an account?
                <router-link to="/signup">Sign up</router-link>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="login-marketing-text flex-1">

        <div class="login-marketing-text-inner-holder">

          <h1>MARS CAPITAL</h1>
          <h2>Smart Investment Space</h2>

          <p>
            Join the professional investors’ community to access private investment opportunities previously only
            available to institutional and high net worth investors.
            <br/>
          </p>
          <p>
            Improve your asset allocation and diversify your portfolio with alternative investments such as:
          </p>
          <ul>
            <li>private debt backed by real estate and other assets in Europe</li>
            <li>global hedge fund managers with proven track records</li>
            <li>other exclusive private investments</li>
          </ul>

        </div>

      </div>

    </div>
  </div>
</template>

<style lang="scss">

.login {

  .forgot-password-line {
    text-align: right;

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .header-logo {
    width: 160px;
    margin-left: 16px;
  }

  background: url(../assets/background.svg);
  min-height: 100%;
  background-repeat: no-repeat;
  background-position: right 0px;
  background-size: 50% auto;


  .login-button {
    margin: 0 auto;
    display: block;
    text-transform: capitalize;
    width: 181px;
    height: 46px;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .form-bottom-text {
    text-align: center;

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .login-form-wrapper-inner-holder {
    width: 60%;
    margin: 15% auto 0;

    h1 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 30px;
    }
  }

  .login-marketing-text-inner-holder {
    width: 60%;
    margin: 15% auto 0;

    h1 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 96px;
      line-height: 92px;
      margin-bottom: 23px;
    }

    h2 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 25px;
      margin-bottom: 50px;
    }

    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }

    ul {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }
  }


}


@media screen and (max-width: 600px) {

  .login {

    background: none;

    .login-holder {
      flex-direction: column;
    }

    .login-marketing-text-inner-holder h1 {
      font-size: 63px;
      line-height: 63px;
    }

    .login-form-wrapper-inner-holder {
      width: 90%;
    }

    .login-marketing-text-inner-holder {
      width: 90%;
    }
  }

}

</style>

<script>
import authRepository from "@/repositories/authRepository";
import cookieService from "@/services/cookieService";

export default {
  name: 'LoginPage',

  data: () => ({
    email: null,
    password: '',
    securityCode: '',
    show: false,
    twoFactorCheck: false,
    valid: true,
    securityCodeErrorMessage: '',
    errorMessage: '',

    rules: {
      required: value => !!value || 'Required.'
    },
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    passwordRules: [
      v => !!v || 'Password is required',
      v => v.length >= 8 || 'Your password must contain at least 8 characters.',
    ]
  }),

  methods: {

    validateCode() {

      this.securityCodeErrorMessage = '';

      if (this.securityCode.length === 6) {

        authRepository.validateCode({
          code: this.securityCode,
          email: this.email
        }).then((response) => {


          if (response.status === 200) {

            if (response.data.match) {

              cookieService.setCookie('access', response.data.access)
              cookieService.setCookie('refresh', response.data.refresh)

              this.$router.push(this.$route.query.redirect || '/')

            } else {

              this.securityCodeErrorMessage = 'Invalid security code'

            }

          } else {

            this.securityCodeErrorMessage = 'Something went wrong. Please, try again later.'

          }

        }).catch((error) => {

          this.processing = false;

          console.log("reason", error.response)

          this.errorMessage = '';

          Object.keys(error.response.data).forEach((key) => {

            this.errorMessage = this.errorMessage + key + ': ' + error.response.data[key] + ' \n\n'

          })

        })

      }

    },

    login() {

      authRepository.login({
        email: this.email,
        password: this.password
      }).then((response) => {

        console.log('login response', response)

        if (response.status === 200) {

          if (response.data.two_factor_check) {

            this.twoFactorCheck = true;

          } else {

            cookieService.setCookie('access', response.data.access)
            cookieService.setCookie('refresh', response.data.refresh)

            this.$router.push(this.$route.query.redirect || '/')

          }

        } else {

          this.errorMessage = ''

          Object.keys(response.data).forEach((key) => {
            this.errorMessage = this.errorMessage + key + ': ' + response.data[key] + ' '
          })

        }

      }).catch((error) => {

        this.errorMessage = ''

        Object.keys(error.response.data).forEach((key) => {
          this.errorMessage = this.errorMessage + key + ': '  + error.response.data[key] + ' '
        })

      })

    }

  }
}
</script>
