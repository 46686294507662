<template>
  <div class="profile-page">
    <h1 class="profile-page-header">Personal Information</h1>
    <div>


      <div class="black-form-header">
        General information
      </div>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation

          class="black-form"
      >

        <div class="black-form-row">

          <v-text-field
              v-model="user.first_name"
              label="First name"

              required
          ></v-text-field>

          <v-text-field
              v-model="user.last_name"
              label="Last Name"
              required
          ></v-text-field>

        </div>

        <div class="black-form-row">

          <v-text-field
              v-model="user.phone"
              label="Phone Number"
              required
          ></v-text-field>

          <v-text-field
              v-bind:readonly="true"
              v-model="user.email"
              label="Email"
              required
          ></v-text-field>

        </div>


        <!--        <div v-for="attachment in user.attachments" v-bind:key="attachment.id">-->

        <!--          <a v-bind:href="attachment.public_path" target="_blank">{{ attachment.name }}</a>-->
        <!--        </div>-->


      </v-form>

      <v-checkbox
          v-model="user.nda_agree"
          v-bind:class="{'disabled-button': user.nda_agree}"
      >

        <template v-slot:label>

          I accept &nbsp;<a href="#" target="_blank">NDA agreement</a>
        </template>

      </v-checkbox>


      <v-checkbox
          v-model="user.privacy_policy_agree"
          v-bind:class="{'disabled-button': user.privacy_policy_agree}"
      >

        <template v-slot:label>

          I accept &nbsp;<a href="#" target="_blank">Terms and Conditions, Privacy and Cookie Policy</a>
        </template>


      </v-checkbox>

      <v-checkbox
          v-model="user.receive_email_notifications"
          label="Receive email notifications"
      ></v-checkbox>

      <button v-on:click="save()"
             class="fancy-button"

      >Save
      </button>


      <!--      <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">-->
      <!--        <h1>Upload Documents</h1>-->
      <!--        <div class="dropbox">-->
      <!--          <input type="file" multiple :name="uploadFieldName" :disabled="isSaving"-->
      <!--                 @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"-->
      <!--                 class="input-file">-->
      <!--          <p v-if="isInitial">-->
      <!--            Drag your file(s) here to begin<br> or click to browse-->
      <!--          </p>-->
      <!--          <p v-if="isSaving">-->
      <!--            Uploading {{ fileCount }} files...-->
      <!--          </p>-->
      <!--        </div>-->
      <!--      </form>-->

    </div>
  </div>
</template>


<style lang="scss">

.profile-page {

  text-align: left ;

  .profile-page-header {
    text-align: left;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 16px;

  }
}

.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

</style>

<script>

import userRepository from "@/repositories/userRepository";

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
  name: 'ProfilePage',

  data: () => ({
    valid: true,
    user: {},

    uploadedFiles: [],
    uploadError: null,
    currentStatus: null,
    uploadFieldName: 'attachments'
  }),

  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  },

  methods: {

    // File upload logic start

    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },

    saveDocument(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;

      userRepository.uploadDocument(this.user.id, formData)
          .then(x => {
            this.uploadedFiles = [].concat(x);
            this.currentStatus = STATUS_SUCCESS;
          })
          .catch(err => {
            this.uploadError = err.response;
            this.currentStatus = STATUS_FAILED;
          });
    },

    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData();

      if (!fileList.length) return;

      // append the files to FormData
      Array
          .from(Array(fileList.length).keys())
          .map(x => {
            formData.append(fieldName, fileList[x], fileList[x].name);
          });

      // save it
      this.saveDocument(formData);
    },

    // File upload logic end


    save() {

      userRepository.update(this.user.id, this.user).then((response) => {
        this.user = response.data

        console.log('user', this.user)
      })

    }
  },

  mounted() {

    this.reset();

    userRepository.getMe().then((response) => {
      this.user = response.data.results[0];

      console.log('user', this.user)
    })

  }
}
</script>
