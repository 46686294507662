<template>
  <div class="nda-page">


    <p>The following web-page contains materials and/or links to the materials (further referred to as the “materials”)
      which represent or contain certain Confidential Information (as defined below).</p>
    <p>By proceeding to the web-page, the User agrees that he/she:</p>
    - is aware of the confidential nature of the materials;<br/>
    - has read, understood and agreed to the below non-disclosure agreement (the “Agreement”), shall be bound by its
    terms and agrees to bear responsibility in the case of breach of the Agreement;<br/>
    - acknowledges and agrees that the materials may contain meta-data, be marked and linked to the User’s authorization
    details, contain unique code or formula that will enable identification of the session and identity of the User, who
    receives access to the particular materials and Confidential Information;<br/>
    - a Person who possesses the Confidential Information as the consequence of its disclosure during the current
    session shall be deemed the User for the purposes of the Agreement and shall be responsible under the Agreement
    accordingly.
    <p>The User agrees for the benefit of MARS Capital S.A., registered address: 2, Cours de Rive, CH-1204, Geneva,
      Switzerland ( the “ Platform Operator”) to be bound by the terms of the following Agreement
    </p>

    <h3>1. Definitions</h3>
    <p>In this Agreement the following words shall have the following meanings:</p>
    <p>“Affiliate” means, with respect to any Person, a Person that directly or indirectly, through one or more
      intermediaries, Controls, is Controlled by, or is under common Control with such Person;</p>
    <p>
      "Confidential Information" shall mean all and any legal, business, financial, operational and any other
      information
      having direct or indirect relation to the operations, processes, plans, intentions, products, knowhow, trade
      secrets, market opportunities, customers and business affairs of the Platform Operator and/or its Affiliates
      and/or
      Persons or entities mentioned in the materials accessible via the Platform which is made available to the User in
      whatever form and media whether identified as confidential and proprietary at the time of disclosure or not and
      irrespectively of the circumstances under which such information is imparted (in confidence or otherwise); any
      notes, summaries and other materials derived therefrom; any copy of the foregoing;
    </p>
    <p>“Control”, including, with correlative meaning, the terms “Controlled by” and “under common Control with”, in
      relation to a body corporate, shall mean the power of a Person to secure that the affairs of the body corporate
      are
      conducted in accordance with the wishes of that Person (i) by means of the holding of shares, or the possession of
      voting power, in or in relation to that or any other body corporate; or (ii) by virtue of any powers conferred by
      the constitutional or corporate documents, or any other document, regulating that or any other body corporate;
      “Person” shall mean any individual, partnership, association, corporation, limited liability company, trust or
      other
      entity, either incorporated or unincorporated;
    </p>
    <p>“Platform” shall mean the website https://sis.mars-cap.com//, including the information platform provided through
      the website;
    </p>
    <p>“Representatives” shall mean the officers, directors, employees, agents, advisors and representatives of each
      Party
      and/or any of its Affiliates.
    </p>

    <h3>2. Obligations of the User</h3>

    <p>2.1. The User hereby irrevocably undertakes to the Platform Operator to comply, and procure the compliance of its
      Affiliates and Representatives, with the following confidentiality requirements:
    </p>
    <p>2.1.1. receive and keep the Confidential Information strictly secret and confidential and not disclose such
      Confidential Information to any third party save as provided hereunder;</p>
    <p>2.1.2. take all necessary measures and precautions to ensure that the confidentiality undertaking is enforced and
      is
      enforceable;
    </p>
    <p>2.1.3. save for the use of the Platform for its direct purpose, not to make any commercial use of or make any
      commercial gain from the Confidential Information without the Platform Operator’s prior written consent.
    </p>
    <p>2.2. The User undertakes to the Platform Operator that, unless expressly permitted by the Platform Operator or
      expressly authorized in the contents of the materials, neither the User nor any of its Affiliates or
      Representatives
      will
    </p>
    - solicit, endeavour to entice away, employ or offer to employ directly or indirectly any officer, employee,
    business associate, business partner, client, potential investee, investor, advisor or consultant of the Platform
    Operator;<br/>
    - otherwise attempt to deal directly or indirectly in any manner with any of the Persons who became known to it as
    the result of receipt of the Confidential Information; or<br/>
    - by-pass, compete, avoid, circumvent, or attempt to circumvent the Platform Operator in connection with to the
      Projects or otherwise by utilizing or deriving any benefit any of the Confidential Information.
    <p>2.3. The obligations, requirements and restrictions established by clauses 2.1 and 2.2 above and by any other
      provisions of this Agreement are continuing and shall be valid and remaining in full force and effect for a period
      of five years from the date when any Confidential Information was last received or accessed by the User, its
      Affiliate or Representative. It is further agreed that those obligations, requirements and restrictions shall
      survive the termination of this Agreement for any reason before the expiry of the above term.
    </p>
    <p>2.4. If the User, its Affiliate or Representative becomes legally compelled to disclose any Confidential
      Information
      to the public, any public authority or official, prompt notice in writing of such fact shall be given to the
      Platform Operator, so that the Platform Operator was enabled to (i) seek an appropriate legal remedy, protective
      order or authorization from any competent authority or official preventing or waiving the requirement of such
      disclosure or (ii) waive the right to enforce compliance with the provisions of this Agreement and consent to the
      disclosure of the relevant information.
      If, following the Platform Operator’s refusal or failure to obtain the necessary remedy, protective order or
      authorisation referred to in the above paragraph or to waive compliance with the relevant provisions and consent
      to
      the disclosure of the Confidential Information, the User, its Affiliate or Representative still is, in the opinion
      of independent legal counsel, compelled to disclose such information under pain of liability and penalty, the
      relevant person is hereby authorised to the disclosure of such portion of the Confidential Information as is
      legally
      required without any liability hereunder.
    </p>
    <p>
      2.5. The User upon being requested by the Platform Operator to do so, will, promptly (i) destroy all notes,
      reports,
      analyses, reviews and documents and other things recording or disclosing the Confidential Information in
      possession
      of the User; (ii) irrevocably expunge all Confidential Information from any computer, word processor, tape, film
      or
      other similar device or media into which or onto which it was put; and further (iii) provide and certify in
      writing
      to the Platform Operator that all the Affiliates and Representatives of the User who had access to the
      Confidential
      Information (of part of it) have done likewise.
    </p>

    <h3>3. Limitation of transferred rights</h3>

    <p>3.1. The User acknowledges and agrees that the property and copyright in Confidential Information disclosed or
      caused to be disclosed by the Platform Operator, including any documents, files and any other items containing any
      Confidential Information belongs to the Platform Operator or its respective Affiliate(s) as the case may be.
    </p>
    <p>3.2. This Agreement shall neither prejudice nor limit the rights of the Platform Operator and its Affiliates in
      respect of any intellectual property rights in the Confidential Information.
    </p>
    <p>3.3. This Agreement shall not be construed to grant the User any license or rights other than as expressly set
      out
      herein in respect of the Confidential Information; nor require the Platform Operator to disclose, or cause the
      disclosure of, any Confidential Information.</p>

    <h3>4. Lability and Remedies</h3>
    <p>4.1. Without prejudice to the foregoing, the User shall be liable for indemnification of the Platform Operator
      for
      any damages and losses incurred as the result of the breach by the User of this Agreement and the User further
      agrees to pay to the Platform Operator an additional fine of EUR10,000 for each case of breach of the Agreement.
    </p>
    <p>4.2. Without prejudice to any other rights and remedies the Platform Operator may have, the User agrees that the
      Confidential Information is valuable, the Platform Operator may be irreparably harmed by any breach of this
      Agreement and that damages may not be an adequate remedy for such breach; accordingly, the User agrees that the
      Platform Operator will be entitled without proof of special damage to the remedies of an injunction and other
      equitable relief for any actual or threatened breach of this Agreement by the User, its Affiliates or
      Representatives.</p>

    <h3>5. Limitation of liability</h3>

    <p>The Platform Operator as well as any of its Affiliates and Representatives gives no warranty or representation in
      relation to the Confidential Information disclosed hereunder. In particular (but without limiting the generality
      of
      the foregoing) no warranty or representation, express or implied, is given as to the accuracy, reliability,
      efficacy
      or completeness of information provided under this Agreement. Neither the Platform Operator nor any of its
      Affiliates and Representatives shall have any liability or owe any duty of care to the User or any other person
      resulting from the disclosure or the use of the Confidential Information.
    </p>

    <h3>6. Miscellaneous</h3>

    <p>6.1 If, at any time, any provision of this Agreement is or becomes illegal, invalid or un­enforceable in any
      respect
      under the applicable law, neither the legality, validity or enforceability of the remaining provisions of this
      Agreement under the governing law nor the legality, validity or enforceability of such provision of this
      Agree­ment
      under the law of any other jurisdiction will in any way be affected or impaired.
    </p>
    <p>6.2 No failure or delay in exercising any rights or remedy under this Agreement will operate as a waiver thereof
      nor
      will any single or partial exercise of any such right or remedy preclude any further exercise thereof or the
      exercise of any other right or remedy under this Agreement.
    </p>

    <h3>7. Governing law and jurisdiction</h3>

    <p>This agreement, and any non-contractual matters relating to this agreement, shall be governed by and construed in
      accordance with the laws of Luxembourg.
    </p>
    <p>
      Any dispute arising out of or in connection with or in respect of the Agreement shall be referred to and finally
      resolved by arbitration under the LCIA Rules, which rules are deemed to be incorporated by reference into this
      agreement and: (i) the number of arbitrators shall be one; (ii) the seat, or legal place, of arbitration shall be
      Luxembourg, Grand-Duchy of Luxembourg; (iii) the language to be used in the arbitral proceedings shall be English;
      and (iv)the governing law of the arbitral proceedings shall be the substantive law of Luxembourg.
    </p>


  </div>
</template>


<style lang="scss">

</style>

<script>

export default {
  name: 'NdaPage',

  data: () => ({}),

  computed: {},

  methods: {

    // File upload logic start

  },
  mounted() {


  }
}
</script>
