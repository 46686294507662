<template>
  <div class="signup">

    <img alt="Marscapital" src="../assets/logo_sis.png" class="header-logo">

    <div class="display-flex flex-space-between signup-holder">

      <div class="signup-form-wrapper flex-1">
        <div class="signup-form-wrapper-inner-holder">
          <div v-if="step === 1">
            <h1 class="text-center">Sign up</h1>
            <div>

              <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
              >
                <v-text-field
                    v-model="email"
                    label="Email"
                    :rules="emailRules"
                    required
                ></v-text-field>

                <v-text-field

                    v-model="password"
                    label="Password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                    :rules="passwordRules"
                    required
                ></v-text-field>

                <v-text-field

                    v-model="password2"
                    label="Repeat Password"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    @click:append="show2 = !show2"
                    :rules="passwordRules.concat(passwordConfirmationRule)"
                    required
                ></v-text-field>


                <button v-on:click="step = 2; $refs.form.resetValidation()"
                        v-bind:class="{'disabled-button': !email || !password || !password2}"
                        class="signup-button fancy-button"

                >Sign up
                </button>


              </v-form>

              <div style="margin-top: 24px;" class="form-bottom-text">
                Already have an account?
                <router-link to="/login">Log In</router-link>
              </div>

            </div>
          </div>

          <div v-if="step === 2">

            <h1 class="text-center">Welcome</h1>

            <p>
              To provide you with the best experience we need to learn more about you.
            </p>

            <div>

              <v-form
                  ref="form"
                  v-model="valid2"
                  lazy-validation
              >

                <v-text-field
                    v-model="first_name"
                    label="First name"
                    :rules="firstNameRules"
                    required
                ></v-text-field>


                <v-text-field
                    v-model="last_name"
                    label="Last Name"
                    :rules="lastNameRules"

                ></v-text-field>

                <div class="display-flex">

                  <vue-country-code class="country-code-select" @onSelect="onSelect" :enabledCountryCode="true"
                                    :preferredCountries="['ch', 'us', 'gb', 'lu']">
                  </vue-country-code>

                  <v-text-field
                      v-model="phone"
                      label="Phone"
                      :rules="phoneRules"

                  ></v-text-field>
                </div>

                <div class="display-flex ">
                  <v-checkbox v-model="privacy_policy_agree"></v-checkbox>
                  <div style="margin-top: 20px">I accept &nbsp;<a href="/privacy-policy" target="_blank">Terms and
                    Conditions, Privacy and Cookie
                    Policy</a></div>
                </div>

                <div class="display-flex ">
                  <v-checkbox v-model="nda_agree"></v-checkbox>
                  <div style="margin-top: 20px">
                    I accept &nbsp;<a href="/nda" target="_blank">NDA agreement</a>
                  </div>
                </div>


                <div class="display-flex flex-space-between">

                  <button class="simple-button signup-back-button" v-on:click="step = 1">Back</button>

                  <button v-on:click="step = 3; valid=true"
                          v-bind:class="{'disabled-button': !first_name || !privacy_policy_agree || !nda_agree}"
                          class="signup-button fancy-button"
                  >Next
                  </button>
                </div>


              </v-form>

            </div>

          </div>

          <div v-if="step === 3">

            <h1 class="text-center">Investor's status</h1>
            <p class="text-center investor-details-link" v-on:click="investorDetailDialog = true">
              Details on investor’s status
            </p>

            <p>Please indicate your category as per FinSA</p>

            <v-radio-group v-model="investor_status">
              <div class="display-flex">
                <v-radio :value="'retail'"></v-radio>
                <div>Retail client</div>
              </div>
              <div class="display-flex">
                <v-radio :value="'retail_professional'"></v-radio>
                <div class="retail-professional-dialog-link" v-on:click="retailProfessionalDialog = true">Retail client
                  <p style="font-size: 14px;">
                    (wishing to be treated as professional client)
                  </p></div>
              </div>

              <div class="display-flex">
                <v-radio :value="'professional'"></v-radio>
                <div>Professional client</div>
              </div>

              <div class="display-flex">
                <v-radio :value="'institutional'"></v-radio>
                <div>Institutional client</div>
              </div>
            </v-radio-group>


            <div class="display-flex flex-space-between">

              <button class="simple-button signup-back-button" v-on:click="step = 2">Back</button>

              <button v-on:click="signup()"
                      v-bind:class="{'disabled-button': !investor_status || processing}"
                      class="g-recaptcha signup-button fancy-button"
                      data-sitekey="6LeSw84eAAAAAEH9fM14GBjXJ2OTSfSpld3B5QDQ"
                      data-action='submit'
              >Next
              </button>
            </div>

            <div v-if="errorMessage" style="color: red; margin-top: 8px;">
              {{ errorMessage }}
            </div>

          </div>

          <div v-if="step === 4">

            <h1 class="text-center">Thank you</h1>

            <p>
              You will get an email with confirmation. Please follow the link from this email to finish your
              registration.
            </p>

          </div>

          <div v-if="step === 5">

            <h1 class="text-center">E-mail is confirmed!</h1>

            <p>
              We are checking your account. Once your account is verified, you will have access to investing on the
              platform.
              Approximate waiting time is 1-2 hours.
            </p>

          </div>

        </div>
      </div>

      <div class="signup-marketing-text flex-1">

        <div class="signup-marketing-text-inner-holder">

          <h1>MARS CAPITAL</h1>
          <h2>Smart Investment Space</h2>

          <p>
            Join the professional investors’ community to access private investment opportunities previously only
            available to institutional and high net worth investors.
            <br/>
          </p>
          <p>
            Improve your asset allocation and diversify your portfolio with alternative investments such as:
          </p>
          <ul>
            <li>private debt backed by real estate and other assets in Europe</li>
            <li>global hedge fund managers with proven track records</li>
            <li>other exclusive private investments</li>
          </ul>

        </div>

      </div>


    </div>

    <v-dialog
        width="970"
        v-model="investorDetailDialog"
    >
      <div class="investor-details-dialog-content">

        <h2 class="investor-details-dialog-title text-center" style="margin-bottom: 32px">Confirmation of the User’s
          investor status</h2>

        <p>The Investor Space (the “Platform”) provides certain information in respect of investment opportunities on
          the
          assumption and based upon undertaking of the Users that no User will make investment or enter into any
          transaction if such transaction will constitute a breach of rules, laws or regulations applicable to such
          User,
          the Platform and the relevant investment vehicle/ issuer.
        </p>

        <p>The investments listed on the Platform require the User to satisfy certain eligibility criteria, including
          status of a professional client, as defined in the Swiss Financial Services Act (“FinSA”), and qualified
          investor status as defined in the Collective Investment Schemes Act (“CISA”). Professional clients under FinSA
          are considered qualified investors under CISA.
        </p>

        <p>The Platform is designated solely for use by persons who satisfy the criteria of professional clients and the
          Users who do not fit such criteria are requested to immediately leave the Platform’s website.
        </p>

        <p>Pursuant to FinSA, some retail clients who possess required knowledge and experience and minimum amount of
          net
          wealth providing the ability to bear financial losses and who wish to be treated as professional clients may
          elect to be treated as professional clients as well as to change their election afterwards (and to be treated
          as
          retail clients) at any time.
        </p>

        <div style="width: 100%; " class="text-center">
          <button v-on:click="investorDetailDialog=false" class="fancy-button invest-button" style="width: 155px"> OK
          </button>
        </div>

        <v-icon v-on:click="investorDetailDialog=false" class="investor-details-dialog-close-button">mdi-close</v-icon>


      </div>
    </v-dialog>

    <v-dialog
        width="970"
        v-model="retailProfessionalDialog"
    >
      <div class="retail-professional-dialog-content">

        <h2 class="retail-professional-dialog-title text-center" style="margin-bottom: 16px">Retail client</h2>
        <p class="text-center" style="margin-bottom: 32px">(wishing to be treated as professional client)</p>

        <p>The User (acting directly or through a private investment structure) declares that: </p>

        <div class="display-flex ">
          <v-checkbox v-model="retail_professional_1"></v-checkbox>
          <div>
            on the basis of training, education and professional experience or on the basis of comparable experience in
            the financial sector, the User possesses the necessary knowledge to understand the risks associated with the
            investments and has at his disposal assets of at least CHF 500,000 assets (excluding direct investments in
            real estate, claims relating to social insurance and occupational pension assets); or
          </div>
        </div>


        <div class="display-flex" style="margin-top: 10px;">
          <v-checkbox v-model="retail_professional_2"></v-checkbox>
          <div>
            the User has at his disposal assets of at least CHF 2 million (excluding direct investments in real estate,
            claims relating to social insurance and occupational pension assets).
          </div>
        </div>

        <div style="display: flex; flex-direction: row; justify-content: center">

          <button
              style="margin-right: 15px; width: 155px;"
              v-on:click="retailProfessionalDialog=false; retail_professional_1=false; retail_professional_2=false; "
              class="simple-button invest-button">Decline
          </button>

          <button v-on:click="retailProfessionalDialog=false"
                  v-bind:class="{'disabled-button': !retail_professional_1 || !retail_professional_2}"
                  class="fancy-button invest-button" style="width: 155px"> Accept
          </button>
        </div>

        <v-icon v-on:click="retailProfessionalDialog=false" class="retail-professional-dialog-close-button">mdi-close
        </v-icon>

      </div>
    </v-dialog>

  </div>
</template>

<style lang="scss">

.retail-professional-dialog-link {
  &:hover {
    opacity: .5;
  }
}

.investor-details-link {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.retail-professional-dialog-content {
  background: #382040;
  padding: 40px;
  position: relative;
}

button.v-icon.retail-professional-dialog-close-button {
  height: 42px;
  width: 42px;
  position: absolute;
  right: 26px;
  top: 26px;
  cursor: pointer;
  text-align: center;

  &:hover {
    opacity: .5;
  }
}

.investor-details-dialog-content {
  background: #382040;
  padding: 40px;
  position: relative;
}

button.v-icon.investor-details-dialog-close-button {
  height: 42px;
  width: 42px;
  position: absolute;
  right: 26px;
  top: 26px;
  cursor: pointer;
  text-align: center;

  &:hover {
    opacity: .5;
  }
}


.signup {

  .header-logo {
    width: 160px;
    margin-left: 16px;
  }

  background: url(../assets/background.svg);
  min-height: 100%;
  background-repeat: no-repeat;
  background-position: right 0px;
  background-size: 50% auto;

  .country-code-select {
    height: 30px;
    margin-right: 10px;
    margin-top: 19px;
    color: #1f1f1f;
  }

  .signup-back-button {
    margin-top: 50px;
    margin-bottom: 40px;
    width: 155px;
    height: 46px;
    border-radius: 5px;
  }

  .signup-button {
    margin: 0 auto;
    display: block;
    text-transform: capitalize;
    width: 155px;
    height: 46px;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    margin-top: 50px;
    margin-bottom: 40px;
    border-radius: 5px;
  }

  .form-bottom-text {
    text-align: center;

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .signup-form-wrapper-inner-holder {
    width: 60%;
    margin: 15% auto 0;

    h1 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 30px;
      margin-bottom: 20px;
    }

    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
    }

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .signup-marketing-text-inner-holder {
    width: 60%;
    margin: 15% auto 0;

    h1 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 96px;
      line-height: 92px;
      margin-bottom: 23px;
    }

    h2 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 25px;
      margin-bottom: 50px;
    }

    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }

    ul {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }
  }


}

@media screen and (max-width: 600px) {

  .signup {

    background: none;

    .signup-holder {
      flex-direction: column;
    }

    .signup-marketing-text-inner-holder h1 {
      font-size: 63px;
      line-height: 63px;
    }

    .signup-form-wrapper-inner-holder {
      width: 90%;
    }

    .signup-marketing-text-inner-holder {
      width: 90%;
    }
  }

}


</style>

<script>


import authRepository from "@/repositories/authRepository";
import cookieService from "@/services/cookieService";

export default {
  name: 'SignupPage',

  data: () => ({
    email: null,
    password: '',
    password2: '',
    first_name: null,
    last_name: '',
    phone: null,

    privacy_policy_agree: null,
    nda_agree: null,

    investorDetailDialog: false,
    retailProfessionalDialog: false,
    investor_status: '',

    retail_professional_1: false,
    retail_professional_2: false,

    show: false,
    show2: false,
    valid: true,
    valid2: true,

    step: 1,

    firstNameRules: [
      v => !!v || 'First name is required',
    ],

    lastNameRules: [
      v => !!v || 'First name is required',
    ],

    phoneRules: [
      v => !!v || 'Phone is required',
      v => /.[\d]]*/.test(v) || "Phone must be entirely numeric."
    ],


    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],

    passwordRules: [
      v => !!v || 'Password is required',
      v => v.length >= 8 || 'Your password must contain at least 8 characters.',
      v => /.[a-zA-Z]]*/.test(v) || "Your password can't be entirely numeric."
    ],

    errorMessage: null,
    countryCode: 41 // default swiss
  }),

  computed: {
    passwordConfirmationRule() {
      return () => (this.password === this.password2) || 'Password must match'
    },
  },

  methods: {

    signup() {

      this.processing = true;

      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6LeSw84eAAAAAEH9fM14GBjXJ2OTSfSpld3B5QDQ', {action: 'submit'}).then((token) => {

          console.log('token', token)

          authRepository.signup({
            password: this.password,
            password2: this.password2,
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
            phone: '+' + this.countryCode + '' + this.phone,
            investor_status: this.investor_status,
            privacy_policy_agree: this.privacy_policy_agree,
            nda_agree: this.nda_agree,
            retail_professional_1: this.retail_professional_1,
            retail_professional_2: this.retail_professional_2
          }).then((response) => {

            console.log('signup response', response)

            this.processing = false;

            if (response.status === 201) {

              cookieService.setCookie('access', response.data.access)
              cookieService.setCookie('refresh', response.data.refresh)

              this.step = 4

              // this.$router.push(this.$route.query.redirect || '/')

            } else {
              this.errorMessage = response.data
            }

          }).catch((error) => {


            this.processing = false;

            console.log("reason", error.response)

            this.errorMessage = '';

            Object.keys(error.response.data).forEach((key) => {

              this.errorMessage = this.errorMessage + key + ': ' + error.response.data[key] + ' \n\n'

            })


          })

        });
      });


    },

    onSelect({name, iso2, dialCode}) {
      console.log(name, iso2, dialCode);
      this.countryCode = parseInt(dialCode, 10)
    },

  },
  mounted() {

    if (this.$route.query.step) {
      this.step = parseInt(this.$route.query.step, 10)

      if (this.step === 5) {

        let token = this.$route.query.confirmation_token

        authRepository.confirmEmail(token)

      }

    }


  }
}
</script>
