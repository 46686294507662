import axios from 'axios'

import router from "@/router";
import cookieService from "@/services/cookieService";

const ax = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Content-type': 'application/json'
    }
})

ax.interceptors.response.use(
    (response) => {
        return response
    },
    (err) => {

        if(err.response.status === 404) {
            router.push('/404')
        }

        if(err.response.status === 403) {
            router.push('/403').catch(()=>{});
        }

        // return other errors
        if (err.response.status !== 401) {
            return new Promise((resolve, reject) => {
                reject(err)
            })
        }

        // error on login
        if (err.response.config.url === '/auth/login/') {
            return new Promise((resolve, reject) => {
                reject(err)
            })
        }
        // error on refresh
        if (err.response.config.url === '/auth/refresh/') {
            console.log('ERRO NO REFRESH')
            router.push('/logout')
            return new Promise((resolve, reject) => {
                reject(err)
            })
        }
        // refresh
        return ax.post('/auth/refresh/', {refresh: cookieService.getCookie('refresh')}, { withCredentials: true }).then(
            success => {
                const config = err.response.config
                config.headers.Authorization = 'Bearer ' + success.data.access
                cookieService.setCookie('access', success.data.access)
                return ax(config)
            }
        )
    }
)

export default ax