
import cookieService from "@/services/cookieService";
import ax from "@/repositories/ax";


export default {
    getList(){
        return ax
            .get(process.env.VUE_APP_API_URL + '/api/order/', {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + cookieService.getCookie('access')
                },
                credentials: 'include'
            })
    },

    getById(id){
        return ax
            .get(process.env.VUE_APP_API_URL + '/api/order/' + id + '/', {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + cookieService.getCookie('access')
                },
                credentials: 'include'
            })
    },

    deleteById(id){
        return ax
            .delete(process.env.VUE_APP_API_URL + '/api/order/' + id + '/', {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + cookieService.getCookie('access')
                },
                credentials: 'include'
            })
    }
}