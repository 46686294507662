<template>
  <div>
    <v-card v-if="item"
        class="project-item">

      <div class="project-item-inner">

        <div class="project-item-front">

          <img v-if="item.images.length" v-bind:src="item.images[0].public_path" alt=""
               class="project-item-thumbnail"
               v-on:click="openProject(item)">
          <img v-if="item.logo_public_path" v-bind:src="item.logo_public_path" class="project-item-logo">
          <div class="project-item-badge badge-status " v-bind:class="badges[item.status]">
            <span v-if="item.status === 'in_progress'">In progress</span>
            <span v-if="item.status === 'exited'">Exited</span>
            <span v-if="item.status === 'coming'">Coming</span>
            <span v-if="item.status === 'invested'">Invested</span>
          </div>
          <div class="project-item-name" v-on:click="openProject(item)"> {{ item.name }}</div>
          <div class="project-item-short-description"> {{ item.short_description }}</div>
          <!--        <div class="project-item-location">{{ item.location }}</div>-->

          <div class="project-item-card-footer">

            <div class="project-item-progressbar-holder"
                 v-if="item.status !== 'coming'"
                 v-bind:class="{'unlimited-fundraising': item.amount_collected > item.minimum_amount_to_collect  && item.minimum_amount_to_collect}">

              <div class="project-item-progressbar-progress"
                   v-bind:style="{width: item.progress_percent + '%'}">

                    <span v-if="item.progress_percent > 30 && !item.minimum_amount_to_collect"><v-icon v-if="item.currency">{{
                        item.currency.icon
                      }}</v-icon> {{ item.amount_collected_pretty_short }}
                    </span>

                <span v-if="item.amount_collected > item.minimum_amount_to_collect && item.minimum_amount_to_collect" class="progress-bar-unlimited-fundraising">UNLIMITED FUNDRAISING</span>

              </div>
              <div class="project-item-progressbar-bg"></div>

            </div>

            <div class="project-item-property-holder">

              <div class="project-item-property">

                <div class="project-item-property-name">Funded</div>
                <div class="project-item-property-value" v-bind:title="item.amount_collected_pretty">
                  {{ item.progress_percent }} %
                </div>

              </div>

              <div class="project-item-property">

                <div class="project-item-property-name">IRR</div>
                <div class="project-item-property-value" v-bind:title="item.irr">
                  {{ item.irr }} %
                </div>

              </div>

              <div class="project-item-property" style="border-right: 0" v-if="!item.minimum_amount_to_collect && item.status !== 'invested'">


                <div class="project-item-property-name">Target</div>
                <div class="project-item-property-value" v-bind:title="item.amount_to_collect_pretty">
                  <v-icon small v-if="item.currency">{{ item.currency.icon }}</v-icon>
                  {{ item.amount_to_collect_pretty }}
                </div>

              </div>

              <div class="project-item-property" style="border-right: 0" v-if="item.minimum_amount_to_collect || item.status === 'invested'">

                <div class="project-item-property-name">Investors</div>
                <div class="project-item-property-value" v-bind:title="item.investors_count">
                  {{ item.investors_count }}
                </div>

              </div>


            </div>

          </div>

        </div>

<!--        <div class="project-item-back">-->
<!--          <div class="project-item-name" v-on:click="openProject(item)"> {{ item.name }}</div>-->

<!--          <div class="project-item-label-holder">-->

<!--            <div class="project-item-label"-->
<!--                 v-for="label in item.labels"  v-bind:key="label.id">-->
<!--              {{label.label.name}}-->
<!--            </div>-->

<!--          </div>-->

<!--          <div class="project-item-short-description" style="margin-bottom: 10px"> {{-->
<!--              item.short_description-->
<!--            }}-->
<!--          </div>-->

<!--          <div class="project-item-progressbar-holder"-->
<!--               v-if="item.status !== 'coming'"-->
<!--               v-bind:class="{'unlimited-fundraising': item.amount_collected > item.minimum_amount_to_collect && item.minimum_amount_to_collect}">-->

<!--            <div class="project-item-progressbar-progress"-->
<!--                 v-bind:style="{width: item.progress_percent + '%'}">-->
<!--              <span v-if="item.progress_percent > 30 && !item.minimum_amount_to_collect"><v-icon v-if="item.currency">{{ item.currency.icon }}</v-icon> {{ item.amount_collected_pretty_short }}</span>-->
<!--              <span v-if="item.amount_collected > item.minimum_amount_to_collect && item.minimum_amount_to_collect" class="progress-bar-unlimited-fundraising">UNLIMITED FUNDRAISING</span>-->
<!--            </div>-->
<!--            <div class="project-item-progressbar-bg"></div>-->

<!--          </div>-->

<!--          <div class="project-item-property-holder">-->

<!--            <div class="project-item-property">-->

<!--              <div class="project-item-property-name">Funded</div>-->
<!--              <div class="project-item-property-value" v-bind:title="item.amount_collected_pretty">-->
<!--                <v-icon small v-if="item.currency">{{ item.currency.icon }}</v-icon>-->
<!--                {{ item.amount_collected_pretty }}-->
<!--              </div>-->

<!--            </div>-->

<!--            <div class="project-item-property">-->

<!--              <div class="project-item-property-name">IRR</div>-->
<!--              <div class="project-item-property-value" v-bind:title="item.irr">-->
<!--                {{ item.irr }} %-->
<!--              </div>-->

<!--            </div>-->

<!--            <div class="project-item-property" style="border-right: 0" v-if="!item.minimum_amount_to_collect">-->

<!--              <div class="project-item-property-name">Target</div>-->
<!--              <div class="project-item-property-value" v-bind:title="item.amount_to_collect_pretty">-->
<!--                <v-icon small v-if="item.currency">{{ item.currency.icon }}</v-icon>-->
<!--                {{ item.amount_to_collect_pretty }}-->
<!--              </div>-->

<!--            </div>-->

<!--            <div class="project-item-property" style="border-right: 0" v-if="item.minimum_amount_to_collect">-->

<!--              <div class="project-item-property-name">Investors</div>-->
<!--              <div class="project-item-property-value" v-bind:title="item.investors_count">-->
<!--                {{ item.investors_count }}-->
<!--              </div>-->

<!--            </div>-->

<!--          </div>-->
<!--          <div class="project-item-property-holder">-->

<!--            <div class="project-item-property">-->

<!--              <div class="project-item-property-name">Tenor</div>-->
<!--              <div class="project-item-property-value">-->
<!--                {{ item.tenor }}-->
<!--              </div>-->

<!--            </div>-->

<!--            <div class="project-item-property">-->

<!--              <div class="project-item-property-name">Type</div>-->
<!--              <div class="project-item-property-value">-->
<!--                {{ item.type }}-->
<!--              </div>-->

<!--            </div>-->

<!--            <div class="project-item-property" style="border-right: 0">-->

<!--              <div class="project-item-property-name">Currency</div>-->
<!--              <div class="project-item-property-value">-->
<!--                <span v-if="item.currency">{{ item.currency.name }}</span>-->
<!--              </div>-->

<!--            </div>-->

<!--          </div>-->

<!--          <div class="project-item-to-detail" v-on:click="openProject(item)">-->
<!--            DETAIL PAGE-->
<!--          </div>-->


<!--        </div>-->

      </div>

    </v-card>
  </div>
</template>

<style lang="scss">


div.project-item.v-card.v-sheet {

  width: 286px;
  height: 462px;
  margin: 16px;
  position: relative;


  border-radius: 5px;
  background-color: transparent;
  box-shadow: none;

  perspective: 1000px;



  //&:hover .project-item-inner,
  //&:focus .project-item-inner {
  //  transform: rotateY(180deg) rotateX(0deg);
  //  -moz-transform: rotateY(180deg) rotateX(0deg);
  //}


  .project-item-inner {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 100%;
    position: absolute;
    transition: transform 0.8s;
    -webkit-transition: -webkit-transform 0.8s;
    -moz-transition: -moz-transform 0.8s;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    padding-bottom: 36px;

    transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    //backface-visibility: hidden;
  }

  .project-item-front,
  .project-item-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    background: #14191D;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  }

  //.project-item-front {
  //  transform: rotateY(180deg);
  //}

  .project-item-back {
    transform: rotateY(180deg);
    z-index: 1;
  }

  .project-item-thumbnail {
    width: 286px;
    height: 231px;
    cursor: pointer;

    &:hover {
      opacity: .7;
    }
  }
}

.project-item-logo {

  position: absolute;
  top: 14px;
  right: 11px;
  width: 41px;
  height: 41px;
  border-radius: 50%;
}

.project-item-label-holder {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.project-item-label {
  font-size: 18.006px;
  line-height: 25px;
  padding: 5px 15px;
  border: 1px solid #fff;
  border-radius: 5px;
  margin: 5px;
}

.project-item-badge {
  border-radius: 5px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;

  color: #FFFFFF;

  position: absolute;
  left: 10px;
  top: 220px;
  padding: 5px 13px;
}

.badge-blue {
  background: #0085BE;
}

.badge-red {
  background: #A13635;
}

.badge-white {
  background: #FFFFFF;
  color: #000;
}

.badge-green {
  background: #35A153;
}


.project-item-name {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18.006px;
  line-height: 25px;
  margin-top: 17px;
  /* identical to box height, or 138% */
  cursor: pointer;
  text-align: center;

  &:hover {
    opacity: .7;
  }
}

.project-item-to-detail {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 25px;
  /* or 103% */

  text-align: center;
  text-transform: uppercase;

  cursor: pointer;
  color: #FFFFFF;
  margin-top: 16px;

  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 16px;

  &:hover {
    opacity: .7;
  }
}

.project-item-thumbnail {
  width: 100%;
}

.project-item-location {
  font-size: 12px;
  color: #1867c0;
}

.project-item-open-button {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.project-item-amount i.v-icon {
  font-size: 16px;
}

.project-item-short-description {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18.006px;
  line-height: 25px;
  text-align: center;
  margin-top: 7px;
  max-height: 75px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.project-item-card-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 95px;
  width: 100%;
  box-sizing: border-box;
  padding-top: 5px;
  overflow: hidden;
}

.project-item-progressbar-holder {
  position: relative;
  width: 258px;
  height: 16px;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;



  &.unlimited-fundraising {
    border-radius: 3px;
    .project-item-progressbar-progress  {
      width: 100% !important;
      background: transparent;
    }
    color: #fff;

    .project-item-progressbar-bg {
      background: transparent;
      border: 1px solid #fff;
    }

    span.progress-bar-unlimited-fundraising {
      font-weight: bold;
      font-size: 13px;
    }
  }

  .project-item-progressbar-bg {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 16px;
  }

  .project-item-progressbar-progress {
    height: 16px;
    background: #35A153;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    text-align: center;
    line-height: 18px;
    text-transform: uppercase;

    i {
      font-size: 14px;
    }
  }

}

.project-item-property-holder {
  overflow: hidden;
  margin-top: 8px;
}

.project-item-property {

  display: inline-block;

  padding: 5px;
  box-sizing: border-box;
  width: 94px;
  position: relative;

  &:last-child:after {
    display: none;
  }

  &:after {
    content: '';
    height: 48px;
    width: 1px;
    display: inline-block;
    background: #fff;
    position: absolute;
    right: 0;
    top: 13px;

  }

  .project-item-property-name {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;

    text-align: center;
    text-transform: uppercase;
  }

  .project-item-property-value {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    white-space: nowrap;

    text-align: center;
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
  }

}



</style>
<script>

export default {
  name: 'ProjectCard',
  props: {
    item: {
      images: [],
      status: ''

    }
  },
  data: () => ({
    badges: {
      'coming': 'badge-white',
      'in_progress': 'badge-blue',
      'invested': 'badge-green',
      'exited': 'badge-red'
    }
  }),

  methods: {
    openProject(item) {
      this.$router.push('/project/' + item.id)
    },
  },
  mounted(){

    console.log("this.item", this.item)

  }
}
</script>
