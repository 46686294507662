
import cookieService from "@/services/cookieService";
import ax from "@/repositories/ax";


export default {
    getList(data){

        return ax
            .get(process.env.VUE_APP_API_URL + '/api/project/?ordering=' + data.sort, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + cookieService.getCookie('access')
                },
                credentials: 'include'
            })
    },

    getById(id){
        return ax
            .get(process.env.VUE_APP_API_URL + '/api/project/' + id + '/', {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + cookieService.getCookie('access')
                },
                credentials: 'include'
            })
    },

    getContentById(id){
        return ax
            .get(process.env.VUE_APP_API_URL + '/api/project/' + id + '/content/', {
                headers: {
                    Authorization: 'Bearer ' + cookieService.getCookie('access')
                },
                credentials: 'include'
            })
    },

    invest(id, data){
        return ax
            .post(process.env.VUE_APP_API_URL + '/api/project/' + id + '/invest/', data, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + cookieService.getCookie('access')
                },
                credentials: 'include'
            })
    },

    interest(id, data){
        return ax
            .post(process.env.VUE_APP_API_URL + '/api/project/' + id + '/interest/', data, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + cookieService.getCookie('access')
                },
                credentials: 'include'
            })
    },
    sendMessage(id, data) {
        return ax
            .post(process.env.VUE_APP_API_URL + '/api/project/' + id + '/send-message/', data, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + cookieService.getCookie('access')
                },
                credentials: 'include'
            })
    },

    getProjectUpdateById(id, updateId){
        return ax
            .get(process.env.VUE_APP_API_URL + '/api/project/' + id + '/update/' + updateId + '/', {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + cookieService.getCookie('access')
                },
                credentials: 'include'
            })
    },

}