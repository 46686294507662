<template>
  <div class="project-order-page">

    <div class="project-order-page-main-holder">

      <div class="project-order-page-project-card hidden-sm-and-down">

        <project-card :item="project"></project-card>
      </div>

      <div class="project-order-page-form" v-if="state === 'init' || state === 'edit'">

        <h2 class="project-order-page-create-order-title">Create Expression of interest</h2>

        <p>
          Expression of interest is a preliminary application that does not commit you to anything. When the opportunity
          starts fundraising, we will notify you and move your application to "Pre-order" status. You will be able to
          pay the pre-order or cancel it.
        </p>

        <div>

          <div>
            <label for="amountInput" class="project-order-page-amount-label">Amount</label>
            <input type="text" id="amountInput" class="project-order-page-amount-input" v-model="amount"
                   v-on:keypress="amountChange($event)">
          </div>


          <div class="project-order-page-amount-caption">
            Minimum ticket:
            <v-icon small v-if="project.currency">{{ project.currency.icon }}</v-icon>
            {{ project.minimum_ticket }}
          </div>

          <div v-if="!amountIsValid" style="color: red">
            {{ amountErrorMessage }}
          </div>

        </div>

        <div class="display-flex" style=" margin-top: 48px;">
          <button class="create-order-button fancy-button" style="margin-right: 10px;"
                  v-bind:class="{'disabled-button': !amountIsValid || !amount || processing || amount === '0'}"
                  v-on:click="createOrder()">Create
          </button>
          <button class="simple-button feedback-button" v-on:click="state = 'invested'" v-if="state === 'edit'">Cancel
          </button>
        </div>


      </div>

      <div class="project-order-page-form" v-if="state === 'invested'">

        <h2 class="project-order-page-create-order-title">Expression of interest has been created</h2>

        <p>
          Expression of interest is a preliminary application that does not commit you to anything. When the opportunity
          starts fundraising, we will notify you and move your application to "Pre-order" status. You will be able to
          pay the pre-order or cancel it.
        </p>

        <button class="simple-button feedback-button" v-on:click="state = 'edit'">Edit</button>

      </div>


    </div>

    <h2 class="project-order-page-header">Instrument</h2>

    <div class="black-table" v-if="project.instrument">

      <div class="black-table-row" v-if="project.instrument.name">

        <div class="black-table-column flex-1">
          Name
        </div>
        <div class="black-table-column flex-3">
          {{ project.instrument.name }}
        </div>

      </div>

      <div class="black-table-row" v-if="project.instrument.issuer">

        <div class="black-table-column flex-1">
          Issuer
        </div>
        <div class="black-table-column flex-3">
          {{ project.instrument.issuer }}
        </div>

      </div>

      <div class="black-table-row" v-if="project.instrument.tenor">

        <div class="black-table-column flex-1">
          Tenor
        </div>
        <div class="black-table-column flex-3">
          {{ project.instrument.tenor }}
        </div>

      </div>

      <div class="black-table-row" v-if="project.instrument.isin">

        <div class="black-table-column flex-1">
          ISIN
        </div>
        <div class="black-table-column flex-3">
          {{ project.instrument.isin }}
        </div>

      </div>

      <div class="black-table-row" v-if="project.instrument.minimum_ticket">

        <div class="black-table-column flex-1">
          Minimum ticket size
        </div>
        <div class="black-table-column flex-3">
          {{ project.instrument.minimum_ticket }}
        </div>

      </div>

      <div class="black-table-row" v-if="project.instrument.security">

        <div class="black-table-column flex-1">
          Security
        </div>
        <div class="black-table-column flex-3">
          {{ project.instrument.security }}
        </div>

      </div>

      <div class="black-table-row" v-if="project.instrument.fees">

        <div class="black-table-column flex-1">
          Fees
        </div>
        <div class="black-table-column flex-3">
          {{ project.instrument.fees }}
        </div>

      </div>

      <div class="black-table-row" v-if="project.instrument.target_irr">

        <div class="black-table-column flex-1">
          Target IRR
        </div>
        <div class="black-table-column flex-3">
          {{ project.instrument.target_irr }}
        </div>

      </div>

      <div class="black-table-row" v-if="project.instrument.coupon">

        <div class="black-table-column flex-1">
          Coupon
        </div>
        <div class="black-table-column flex-3">
          {{ project.instrument.coupon }}
        </div>

      </div>

      <div class="black-table-row" v-if="project.instrument.arranger">

        <div class="black-table-column flex-1">
          Arranger
        </div>
        <div class="black-table-column flex-3">
          {{ project.instrument.arranger }}
        </div>

      </div>

      <div class="black-table-row" v-if="project.instrument.paying_agent">

        <div class="black-table-column flex-1">
          Paying agent
        </div>
        <div class="black-table-column flex-3">
          {{ project.instrument.paying_agent }}
        </div>

      </div>

      <div class="black-table-row" v-if="project.instrument.clearing">

        <div class="black-table-column flex-1">
          Clearing
        </div>
        <div class="black-table-column flex-3">
          {{ project.instrument.clearing }}
        </div>

      </div>

      <div class="black-table-row" v-if="project.instrument.valuation_dates">

        <div class="black-table-column flex-1">
          Valuation dates
        </div>
        <div class="black-table-column flex-3">
          {{ project.instrument.valuation_dates }}
        </div>

      </div>

      <div class="black-table-row" v-if="project.instrument.liquidity">

        <div class="black-table-column flex-1">
          Liquidity
        </div>
        <div class="black-table-column flex-3">
          {{ project.instrument.liquidity }}
        </div>

      </div>

      <div class="black-table-row" v-if="project.instrument.rebalancing_and_calculation_agent">

        <div class="black-table-column flex-1">
          Rebalancing and Calculation Agent
        </div>
        <div class="black-table-column flex-3">
          {{ project.instrument.rebalancing_and_calculation_agent }}
        </div>

      </div>


    </div>

  </div>
</template>

<style lang="scss">

.project-order-page {

  .order-attachment-content {
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 15%);
    width: 100%;
    background: #000;
    position: relative;
    white-space: pre-line;
  }

  .copy-order-content-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    &:hover {
      opacity: .7;
    }
  }

  .project-order-page-main-holder {
    display: flex;
    margin-bottom: 28px;
  }

  .project-order-page-create-order-title {
    text-align: left;
    margin-bottom: 38px;
  }

  .project-order-page-project-card {
    margin-right: 48px;
  }

  .project-order-page-form {
    text-align: left;
  }

  .project-order-page-amount-label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    color: #FFFFFF;
    margin-right: 14px;
  }

  .project-order-page-amount-caption {
    margin-top: 11px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18.006px;
    line-height: 25px;
    /* or 138% */

    text-align: left;
    margin-left: 88px;

    color: #FFFFFF;
  }

  .project-order-page-amount-input {
    min-width: 220px;
    border-bottom: 1px solid #fff;
    color: #ffffff;
    outline: none;
  }

  .create-order-button {


    //padding: 5px 35px;
    //background: #A44539;
    //border: 2px solid #FFFFFF;
    //box-sizing: border-box;
    //border-radius: 5px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    /* or 125% */

    text-align: center;

    color: #FFFFFF;

    &:hover {
      opacity: .7;
    }

  }

  .project-order-page-header {
    text-align: center;

    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;

    color: #FFFFFF;

  }

  .black-table {
    background: transparent;
    box-shadow: none;

    .black-table-row {
      border: none;
    }
  }

  .project-item-property-holder {
    display: flex;
  }

  .project-item-property {
    width: auto;
    flex: 1;
  }

  .order-attachment {
    text-align: left;
    display: flex;
    margin-bottom: 50px;
  }

  .order-attachment-filename {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height, or 125% */

    text-decoration-line: underline;

    color: #FFFFFF;
  }

  .order-attachment-description {
    margin-top: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    /* or 167% */


    color: #FFFFFF;
  }

  .order-attachment-icon {
    margin-right: 32px;
    font-size: 64px;
  }

}


@media screen and (max-width: 600px) {

  .project-order-page {

    .black-table {
      .black-table-row {
        flex-direction: column;
      }

      .black-table-column:nth-child(2) {
        padding-left: 40px;
      }
    }

  }
}

</style>

<script>


import projectRepository from "@/repositories/projectRepository";
import numbersFormatter from "@/helpers/numbersFormatter";
import userRepository from "@/repositories/userRepository";
import orderRepository from "@/repositories/orderRepository";
import ProjectCard from "@/components/ProjectCard";
import copy from 'copy-to-clipboard';

export default {
  name: 'OrderPage',

  components: {ProjectCard},

  data: () => ({
    project: {
      images: [],
      instrument: {}
    },
    order: {},
    amount: 0,
    progress_percent: 0,
    state: 'init',
    amountIsValid: true,
    amountErrorMessage: '',
    processing: false
  }),

  methods: {

    strip(html) {
      var tmp = document.implementation.createHTMLDocument("New").body;
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },

    copyOrderContent() {

      copy(this.strip(this.order.order_content));

    },

    amountChange(event) {

      this.amountErrorMessage = '';
      this.amountIsValid = true;
      this.amount = this.amount.replace(/^0+/, '')

      console.log('event', event);

      let char = String.fromCharCode(event.keyCode);

      console.log('char', char);

      if (!this.amount && char === '0') {
        event.preventDefault();
      }

      if (/[0-9]+$/.test(char)) {
        return true
      } else {
        event.preventDefault();
      }

    },

    createOrder() {

      if (parseInt(this.amount, 10) < this.project.minimum_ticket) {
        this.amountIsValid = false;
        this.amountErrorMessage = 'The entered amount is less than the minimum ticket'
        return
      }

      this.processing = true

      projectRepository.invest(this.project.id, {amount: this.amount, order: this.order.id}).then((response) => {

        this.order = response.data

        console.log('response', response);

        this.processing = false;


        this.state = 'invested';

      })

    },

    async getUser() {
      return userRepository.getMe().then((response) => {
        this.user = response.data.results[0];

        console.log('user', this.user)
      })
    },

    getOrder() {

      orderRepository.getById(this.$route.params.id).then((response) => {

        this.order = response.data

        console.log('this.order', this.order);

        if (this.order.status !== 'interest') {
          this.state = 'invested';
        }

        if (this.order.amount) {
          this.state = 'invested';
        }

        this.getProject(this.order.project.id)

      })

    },

    getProject(id) {

      projectRepository.getById(id).then((response) => {

        this.project = response.data

        this.project.amount_collected_pretty = numbersFormatter.numberWithSpaces(this.project.amount_collected)
        this.project.amount_to_collect_pretty = numbersFormatter.numberWithSpaces(this.project.amount_to_collect)
        this.project.minimum_ticket_pretty = numbersFormatter.numberWithSpaces(this.project.minimum_ticket)

        this.progress_percent = Math.floor(this.project.amount_collected / (this.project.amount_to_collect / 100))


        console.log('project', this.project)

      })

    }

  },

  async mounted() {

    await this.getUser();
    this.getOrder();

  }

}
</script>
