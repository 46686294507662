import axios from 'axios';

import cookieService from "@/services/cookieService";
import ax from "@/repositories/ax";

export default {
    login(data) {
        return axios
            .post(process.env.VUE_APP_API_URL + '/auth/login/', {
                email: data.email,
                password: data.password
            }, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json'
                }
            })
    },

    logout() {
        return axios
            .post(process.env.VUE_APP_API_URL + '/auth/logout/', {}, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + cookieService.getCookie('access')
                },
                credentials: 'include'
            })
    },

    refresh(refresh) {
        return axios
            .post(process.env.VUE_APP_API_URL + '/auth/refresh/', {
                refresh: refresh
            }, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json'
                }
            })
    },

    signup(data) {
        return axios
            .post(process.env.VUE_APP_API_URL + '/auth/register/', data, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json'
                }
            })
    },

    validateCode(data) {
        return ax
            .post(process.env.VUE_APP_API_URL + '/api/user/two-factor/validate-code/', data, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json'
                }
            })
    },

    initPasswordReset(data) {
        return axios
            .post(process.env.VUE_APP_API_URL + '/auth/password-reset/', {
                email: data.email
            }, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json'
                }
            })
    },

    passwordReset(data) {
        return axios
            .post(process.env.VUE_APP_API_URL + '/auth/password-reset/confirm/', {
                token: data.token,
                password: data.password
            }, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json'
                }
            })
    },

    confirmEmail(token) {
        return axios
            .get(process.env.VUE_APP_API_URL + '/auth/confirm-email/?confirmation_token=' + token, {}, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                }
            })
    },

}