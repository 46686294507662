<template>
  <div class="projects">

    <!--    <div class="project-filter-sidebar">-->
    <!--      &nbsp;-->
    <!--    </div>-->

    <div class="projects-content-holder">

      <div class="project-list-top-panel">
        <h1 class="project-list-title">Opportunities ({{ projectCount }})</h1>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">

            <div class="projects-sort-holder" v-bind="attrs"
                 v-on="on">
              {{ sorts[activeSort].name }}
              <v-icon >mdi-chevron-down
              </v-icon>
            </div>

          </template>
          <div class="projects-sort-dropdown-holder">
            <div class="projects-sort-dropdown-content">
              <div class="projects-sort-dropdown-item">
                <span v-on:click="sortProjects('newest')">Newest</span>
              </div>
              <div class="projects-sort-dropdown-item">
                <span v-on:click="sortProjects('oldest')">Oldest</span>
              </div>
              <div class="projects-sort-dropdown-item">
                <span v-on:click="sortProjects('most_funded')">Most Funded</span>
              </div>
              <div class="projects-sort-dropdown-item">
                <span v-on:click="sortProjects('high_irr')">Highest IRR</span>
              </div>
            </div>

          </div>
        </v-menu>
      </div>
      <div class="projects-card-holder">

        <project-card  v-for="item in projects" v-bind:key="item.id" :item="item"></project-card>

      </div>

    </div>

  </div>
</template>

<style lang="scss">

.project-list-top-panel {
  display: flex;
  justify-content: space-between;
}

.projects-sort-dropdown-content {
  background: #14191D;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.projects-sort-dropdown-item {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */
  padding: 4px 8px;

  text-align: left;
  cursor: pointer;

  color: #FFFFFF;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

.projects {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.project-filter-sidebar {
  flex: 1;
}

.projects-content-holder {
  flex: 3;
}

.projects-card-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: normal;
}

.project-list-title {
  text-align: left;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 18px;
  margin-left: 16px;


  color: #FFFFFF;
}

</style>

<script>

import projectRepository from "@/repositories/projectRepository";
import numbersFormatter from "@/helpers/numbersFormatter"

import ProjectCard from "@/components/ProjectCard";


export default {
  name: 'ProjectsListPage',
  components: {ProjectCard},

  data: () => ({
    projects: [],
    projectCount: 0,


    activeSort: 'newest',
    sorts: {
      'newest': {
        name: 'Newest',
        value: '-created'
      },
      'oldest': {
        name: 'Oldest',
        value: 'created'
      },
      'most_funded': {
        name: 'Most Funded',
        value: '-amount_collected'
      },
      'high_irr': {
        name: 'Highest IRR',
        value: '-irr'
      }
    },


  }),

  methods: {

    sortProjects(sortKey) {

      this.activeSort = sortKey
      this.getProjects();

    },



    getProjects() {

      projectRepository.getList({
        sort: this.sorts[this.activeSort].value
      }).then((response) => {

        this.projectCount = response.data.count;

        this.projects = response.data.results.map((item) => {

          item.amount_collected_pretty = numbersFormatter.numberWithK(item.amount_collected)
          item.amount_to_collect_pretty = numbersFormatter.numberWithK(item.amount_to_collect)
          item.progress_percent = Math.floor(item.amount_collected / (item.amount_to_collect / 100))
          item.amount_collected_pretty_short = numbersFormatter.numberWithK(item.amount_collected)

          return item

        });

        console.log('projects', this.projects)

      })

    }
  },

  mounted() {

    this.getProjects();

  }
}
</script>
