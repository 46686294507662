<template>
  <div class="permission-denied-page">

    <h1>403</h1>
    <div>
     Access restricted
    </div>

    <p class="permission-denied-page-go-back">Go back to
      <router-link to="/login">Log in</router-link>
    </p>

  </div>
</template>

<style lang="scss">

.permission-denied-page {
  h1 {
    font-size: 96px;
    margin-bottom: 0;
  }

  .permission-denied-page-go-back {
    margin-top: 24px;

    a {
      color: #fff;
      text-decoration: underline;
    }

  }
}

</style>

<script>


export default {
  name: 'PermissionDeniedPage',

  data: () => ({

  }),

  methods: {},
  mounted() {

  }

}
</script>
