
import cookieService from "@/services/cookieService";
import ax from "@/repositories/ax";
import axios from 'axios'


export default {
    getMe(){
        return ax
            .get(process.env.VUE_APP_API_URL + '/api/user/', {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + cookieService.getCookie('access')
                },
                credentials: 'include'
            })
    },

    update(id, data){
        return ax
            .put(process.env.VUE_APP_API_URL + '/api/user/' + id + '/', data, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + cookieService.getCookie('access')
                },
                credentials: 'include'
            })
    },

    uploadDocument(id, data) {

        return axios.post(process.env.VUE_APP_API_URL + '/api/user/' + id + '/document/', data, {
            headers: {
                Authorization: 'Bearer ' + cookieService.getCookie('access')
            },
            credentials: 'include'
        })
            // get data
            .then(x => x.data)

    },

    generateCode(){
        return ax
            .get(process.env.VUE_APP_API_URL + '/api/user/two-factor/generate-code/',  {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + cookieService.getCookie('access')
                },
                credentials: 'include'
            })
    },

    updateOTP(id, data) {
        return ax
            .put(process.env.VUE_APP_API_URL + '/api/user/two-factor/' + id + '/', data, {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + cookieService.getCookie('access')
                },
                credentials: 'include'
            })
    },

    deleteOTP(id) {
        return ax
            .delete(process.env.VUE_APP_API_URL + '/api/user/two-factor/' + id + '/', {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + cookieService.getCookie('access')
                },
                credentials: 'include'
            })
    }


}