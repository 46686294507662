<template>
  <div class="portfolio">

    <!-- Desktop template start -->
    <div class="hidden-sm-and-down">

      <h1 class="portfolio-page-header">My Portfolio</h1>

      <div class="black-table-tab-holder">

        <div class="black-table-tab" v-bind:class="{'active': activeTab === 'all'}" v-on:click="setActiveTab('all')">All
          ({{ allItems.length }})
        </div>
        <div class="black-table-tab" v-bind:class="{'active': activeTab === 'eoi'}" v-on:click="setActiveTab('eoi')">
          Expression of interest ({{ interestOrders.length }})
        </div>
        <div class="black-table-tab" v-bind:class="{'active': activeTab === 'preorder'}"
             v-on:click="setActiveTab('preorder')">Pre-order ({{ preorderOrders.length }})
        </div>
        <div class="black-table-tab" v-bind:class="{'active': activeTab === 'subscribed'}"
             v-on:click="setActiveTab('subscribed')">Subscribed ({{ pendingOrders.length }})
        </div>
        <div class="black-table-tab" v-bind:class="{'active': activeTab === 'invested'}"
             v-on:click="setActiveTab('invested')">Invested ({{ executedTransactions.length }})
        </div>
        <div class="black-table-tab" v-bind:class="{'active': activeTab === 'exited'}"
             v-on:click="setActiveTab('exited')">Exited ({{ exitedTransactions.length }})
        </div>

      </div>

      <div class="black-table" v-if="activeTab === 'all'">

        <div class="black-table-header-row">
          <div class="black-table-column black-table-column-header flex-2">
            Name
          </div>
          <div class="black-table-column black-table-column-header">
            Investment Status
          </div>
          <div class="black-table-column black-table-column-header">
            Valid Until
          </div>
          <div class="black-table-column black-table-column-header">
            Target IRR
          </div>
          <div class="black-table-column black-table-column-header">
            Opportunity Status
          </div>
          <div class="black-table-column black-table-column-header" style="padding-right: 36px;">
            Amount
          </div>
        </div>

        <div class="black-table-row" v-for="item in allItems" v-bind:key="item._index">
          <div class="black-table-column table-portfolio-project-name">
            <div class="table-project-logo">
              <img v-bind:src="item.project.logo_public_path" alt="">
            </div>
            <span v-bind:title="item.project.name">{{ item.project.name }}</span>
          </div>
          <div class="black-table-column">
            {{item.fancy_status}}
          </div>
          <div class="black-table-column">
            {{ item.valid_until_pretty }}
          </div>
          <div class="black-table-column">
            {{ item.project.irr }} %
          </div>
          <div class="black-table-column">
            {{ item.project.fancy_status }}
          </div>
          <div class="black-table-column position-relative" style="padding-right: 36px;">

            <span v-if="item.amount_pretty">

              <v-icon v-if="item.project.currency">{{ item.project.currency.icon }}</v-icon>
              {{ item.amount_pretty }}

            </span>
            <span v-if="!item.amount_pretty">-</span>


            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">

                <div class="portfolio-table-item-button">
                  <v-icon v-bind="attrs"
                          v-on="on">mdi-dots-vertical
                  </v-icon>
                </div>

              </template>
              <div class="portfo  lio-table-item-dropdown-holder">
                <div class="portfolio-table-item-dropdown-content">
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToProject(item)">Opportunity</span>
                  </div>
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToOrder(item)">Order</span>
                  </div>
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="deleteOrder(item)">Delete</span>
                  </div>
                </div>

              </div>
            </v-menu>
          </div>
        </div>

        <div class="black-table-row portfolio-row-no-items" v-if="!allItems.length">

          <v-icon>mdi-alert-circle-outline</v-icon>
          This section displays your activity

        </div>

      </div>


      <!--      <div class="portfolio-page-table-header">Expression of Interest</div>-->
      <div class="black-table" v-if="activeTab === 'eoi'">

        <div class="black-table-header-row">
          <div class="black-table-column black-table-column-header flex-2">
            Name
          </div>
          <div class="black-table-column black-table-column-header">
            Instrument
          </div>
          <div class="black-table-column black-table-column-header">
            Valid Until
          </div>
          <div class="black-table-column black-table-column-header">
            Target IRR
          </div>
          <div class="black-table-column black-table-column-header" style="padding-right: 36px;">
            Opportunity Status
          </div>
        </div>
        <div class="black-table-row" v-for="item in interestOrders" v-bind:key="item.id">
          <div class="black-table-column table-portfolio-project-name">
            <div class="table-project-logo">
              <img v-bind:src="item.project.logo_public_path" alt="">
            </div>
            <span v-bind:title="item.project.name">{{ item.project.name }}</span>
          </div>
          <div class="black-table-column">
            {{ item.project.instrument_type }}
          </div>
          <div class="black-table-column">
            {{ item.valid_until_pretty }}
          </div>
          <div class="black-table-column">
            {{ item.project.irr }} %

          </div>
          <div class="black-table-column position-relative" style="padding-right: 36px;">
            <span
                v-if="item.project.amount_collected > item.project.minimum_amount_to_collect && item.project.minimum_amount_to_collect">
              Unlim. Fundraising
            </span>

            <span
                v-if="!(item.project.amount_collected > item.project.minimum_amount_to_collect && item.project.minimum_amount_to_collect)">{{
                item.project.fancy_status
              }}</span>


            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">

                <div class="portfolio-table-item-button">
                  <v-icon v-bind="attrs"
                          v-on="on">mdi-dots-vertical
                  </v-icon>
                </div>

              </template>
              <div class="portfo  lio-table-item-dropdown-holder">
                <div class="portfolio-table-item-dropdown-content">
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToProject(item)">Opportunity</span>
                  </div>
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToInterest(item)">Order</span>
                  </div>
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="deleteOrder(item)">Delete</span>
                  </div>
                </div>

              </div>
            </v-menu>
          </div>
        </div>

        <div class="black-table-row portfolio-row-no-items" v-if="!interestOrders.length">

          <v-icon>mdi-alert-circle-outline</v-icon>
          This section displays your expression of interest

        </div>

      </div>

      <div class="black-table" v-if="activeTab === 'preorder'">

        <div class="black-table-header-row">
          <div class="black-table-column black-table-column-header flex-2">
            Name
          </div>
          <div class="black-table-column black-table-column-header">
            Instrument
          </div>
          <div class="black-table-column black-table-column-header">
            Valid Until
          </div>
          <div class="black-table-column black-table-column-header">
            Target IRR
          </div>
          <div class="black-table-column black-table-column-header" style="padding-right: 36px;">
            Opportunity Status
          </div>
        </div>
        <div class="black-table-row" v-for="item in preorderOrders" v-bind:key="item.id">
          <div class="black-table-column table-portfolio-project-name">
            <div class="table-project-logo">
              <img v-bind:src="item.project.logo_public_path" alt="">
            </div>
            <span v-bind:title="item.project.name">{{ item.project.name }}</span>
          </div>
          <div class="black-table-column">
            {{ item.project.instrument_type }}
          </div>
          <div class="black-table-column">
            {{ item.valid_until_pretty }}
          </div>
          <div class="black-table-column">
            {{ item.project.irr }} %

          </div>
          <div class="black-table-column position-relative" style="padding-right: 36px;">
            <span
                v-if="item.project.amount_collected > item.project.minimum_amount_to_collect && item.project.minimum_amount_to_collect">
              Unlim. Fundraising
            </span>

            <span
                v-if="!(item.project.amount_collected > item.project.minimum_amount_to_collect && item.project.minimum_amount_to_collect)">{{
                item.project.fancy_status
              }}</span>


            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">

                <div class="portfolio-table-item-button">
                  <v-icon v-bind="attrs"
                          v-on="on">mdi-dots-vertical
                  </v-icon>
                </div>

              </template>
              <div class="portfo  lio-table-item-dropdown-holder">
                <div class="portfolio-table-item-dropdown-content">
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToProject(item)">Opportunity</span>
                  </div>
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToOrder(item)">Order</span>
                  </div>
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="deleteOrder(item)">Delete</span>
                  </div>
                </div>

              </div>
            </v-menu>
          </div>
        </div>

        <div class="black-table-row portfolio-row-no-items" v-if="!preorderOrders.length">

          <v-icon>mdi-alert-circle-outline</v-icon>
          This section displays your pre-orders

        </div>

      </div>

      <!--      <div class="portfolio-page-table-header">Subscribed</div>-->

      <div class="black-table" v-if="activeTab === 'subscribed'">

        <div class=" black-table-header-row">
          <div class="black-table-column black-table-column-header flex-2">
            Name
          </div>
          <div class="black-table-column black-table-column-header">
            Order
          </div>
          <div class="black-table-column black-table-column-header">
            Valid Until
          </div>
          <div class="black-table-column black-table-column-header">
            Amount
          </div>
          <div class="black-table-column black-table-column-header">
            Payment Status
          </div>
        </div>

        <div class="black-table-row" v-for="item in pendingOrders" v-bind:key="item.id">
          <div class="black-table-column table-portfolio-project-name">
            <div class="table-project-logo">
              <img v-bind:src="item.project.logo_public_path" alt="">
            </div>
            <span v-bind:title="item.project.name">{{ item.project.name }}</span>
          </div>
          <div class="black-table-column">
            {{ item.reference }}
          </div>
          <div class="black-table-column">
            {{ item.valid_until_pretty }}
          </div>
          <div class="black-table-column">
            {{ item.amount_pretty }}
            <v-icon v-if="item.project.currency">{{ item.project.currency.icon }}</v-icon>
          </div>
          <div class="black-table-column position-relative">
            <v-icon style="color: red">mdi-alert</v-icon>
            Waiting for payment

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">

                <div class="portfolio-table-item-button">
                  <v-icon v-bind="attrs"
                          v-on="on">mdi-dots-vertical
                  </v-icon>
                </div>

              </template>
              <div class="portfolio-table-item-dropdown-holder">
                <div class="portfolio-table-item-dropdown-content">
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToProject(item)">Opportunity</span>
                  </div>
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToOrder(item)">Order</span>
                  </div>
                </div>

              </div>
            </v-menu>
          </div>
        </div>

        <div class="black-table-row portfolio-row-no-items" v-if="!pendingOrders.length">

          <v-icon>mdi-alert-circle-outline</v-icon>
          This section displays your orders

        </div>

      </div>

      <!--      <div class="portfolio-page-table-header">Invested</div>-->

      <div class="black-table" v-if="activeTab === 'invested'">

        <div class=" black-table-header-row">
          <div class="black-table-column black-table-column-header flex-2">
            Name
          </div>
          <div class="black-table-column black-table-column-header">
            Instrument
          </div>
          <div class="black-table-column black-table-column-header">
            Expected Maturity
          </div>
          <div class="black-table-column black-table-column-header">
            Amount
          </div>
          <div class="black-table-column black-table-column-header">
            Opportunity Status
          </div>
        </div>

        <div class="black-table-row" v-for="item in executedTransactions" v-bind:key="item.id">
          <div class="black-table-column table-portfolio-project-name">
            <div class="table-project-logo">
              <img v-bind:src="item.project.logo_public_path" alt="">
            </div>

            <span v-bind:title="item.project.name">{{ item.project.name }}</span>
          </div>
          <div class="black-table-column">
            {{ item.project.instrument_type }}
          </div>
          <div class="black-table-column">
            {{ item.project.maturity_date }}
          </div>
          <div class="black-table-column">
            <v-icon v-if="item.project.currency">{{ item.project.currency.icon }}</v-icon>
            {{ item.amount_pretty }}
          </div>
          <div class="black-table-column position-relative">

            <span
                v-if="item.project.amount_collected > item.project.minimum_amount_to_collect && item.project.minimum_amount_to_collect">
              Unlim. Fundraising
            </span>

            <span
                v-if="!(item.project.amount_collected > item.project.minimum_amount_to_collect && item.project.minimum_amount_to_collect)">{{
                item.project.fancy_status
              }}</span>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">

                <div class="portfolio-table-item-button">
                  <v-icon v-bind="attrs"
                          v-on="on">mdi-dots-vertical
                  </v-icon>
                </div>

              </template>
              <div class="portfolio-table-item-dropdown-holder">
                <div class="portfolio-table-item-dropdown-content">
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToProject(item)">Opportunity</span>
                  </div>
                </div>

              </div>
            </v-menu>
          </div>
        </div>

        <div class="black-table-row portfolio-row-no-items" v-if="!executedTransactions.length">

          <v-icon>mdi-alert-circle-outline</v-icon>
          This section displays your investments

        </div>

      </div>

      <!--      <div class="portfolio-page-table-header">Exited</div>-->

      <div class="black-table" v-if="activeTab === 'exited'">

        <div class="black-table-header-row">
          <div class="black-table-column black-table-column-header flex-2">
            Name
          </div>
          <div class="black-table-column black-table-column-header">
            Instrument
          </div>
          <div class="black-table-column black-table-column-header">
            Matured
          </div>
          <div class="black-table-column black-table-column-header">
            Amount
          </div>
          <div class="black-table-column black-table-column-header">
            P&L
          </div>
        </div>

        <div class="black-table-row" v-for="item in exitedTransactions" v-bind:key="item.id">
          <div class="black-table-column table-portfolio-project-name">
            <div class="table-project-logo">
              <img v-bind:src="item.project.logo_public_path" alt="">
            </div>
            <span v-bind:title="item.project.name">{{ item.project.name }}</span>
          </div>
          <div class="black-table-column">
            {{ item.project.instrument_type }}
          </div>
          <div class="black-table-column">
            {{ item.project.maturity_date }}
          </div>
          <div class="black-table-column">
            <v-icon v-if="item.project.currency">{{ item.project.currency.icon }}</v-icon>
            {{ item.amount_pretty }}
          </div>
          <div class="black-table-column position-relative">
            <v-icon v-if="item.project.currency">{{ item.project.currency.icon }}</v-icon>
            {{ item.profit_pretty }}

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">

                <div class="portfolio-table-item-button">
                  <v-icon v-bind="attrs"
                          v-on="on">mdi-dots-vertical
                  </v-icon>
                </div>

              </template>
              <div class="portfolio-table-item-dropdown-holder">
                <div class="portfolio-table-item-dropdown-content">
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToProject(item)">Opportunity</span>
                  </div>
                </div>

              </div>
            </v-menu>
          </div>
        </div>

        <div class="black-table-row portfolio-row-no-items" v-if="!exitedTransactions.length">

          <v-icon>mdi-alert-circle-outline</v-icon>
          The section displays completed projects with your investments

        </div>

      </div>

    </div>
    <!-- Desktop template end -->


    <!-- Mobile template start -->
    <div class="portfolio-mobile hidden-md-and-up">

      <h1 class="portfolio-page-header">My Portfolio</h1>


      <v-menu offset-y >
        <template v-slot:activator="{ on, attrs }">

          <div class="projects-sort-holder" style="margin-top: 50px; margin-bottom: 12px" v-bind="attrs"
               v-on="on">
            <span v-if="activeTab === 'all'">All ({{ allItems.length }})</span>
            <span v-if="activeTab === 'eoi'">Expression of interest ({{ interestOrders.length }})</span>
            <span v-if="activeTab === 'preorder'">Pre-order ({{ preorderOrders.length }})</span>
            <span v-if="activeTab === 'subscribed'">Subscribed ({{ pendingOrders.length }})</span>
            <span v-if="activeTab === 'invested'">Invested ({{ executedTransactions.length }})</span>
            <span v-if="activeTab === 'exited'">Exited ({{ exitedTransactions.length }})</span>
            <v-icon >mdi-chevron-down</v-icon>
          </div>

        </template>
        <div class="projects-sort-dropdown-holder">
          <div class="projects-sort-dropdown-content">
            <div class="projects-sort-dropdown-item">
              <span v-on:click="setActiveTab('all')">All ({{ allItems.length }})</span>
            </div>
            <div class="projects-sort-dropdown-item">
              <span v-on:click="setActiveTab('eoi')">Expression of interest ({{ interestOrders.length }})</span>
            </div>
            <div class="projects-sort-dropdown-item">
              <span v-on:click="setActiveTab('preorder')">Pre-order ({{ preorderOrders.length }})</span>
            </div>
            <div class="projects-sort-dropdown-item">
              <span v-on:click="setActiveTab('subscribed')">Subscribed ({{ pendingOrders.length }})</span>
            </div>
            <div class="projects-sort-dropdown-item">
              <span v-on:click="setActiveTab('invested')">Invested ({{ executedTransactions.length }})</span>
            </div>
            <div class="projects-sort-dropdown-item">
              <span v-on:click="setActiveTab('exited')">Exited ({{ exitedTransactions.length }})</span>
            </div>
          </div>

        </div>
      </v-menu>

      <div class="black-table" v-if="activeTab === 'all'">

        <div class="black-table-row" v-for="item in allItems" v-bind:key="item._index">
          <div class="black-table-column table-portfolio-project-name position-relative black-table-bottom-border">
            <div class="table-project-logo">
              <img v-bind:src="item.project.logo_public_path" alt="">
            </div>
            <span v-bind:title="item.project.name">{{ item.project.name }}</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">

                <div class="portfolio-table-item-button">
                  <v-icon v-bind="attrs"
                          v-on="on">mdi-dots-vertical
                  </v-icon>
                </div>

              </template>
              <div class="portfolio-table-item-dropdown-holder">
                <div class="portfolio-table-item-dropdown-content">
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToProject(item)">Opportunity</span>
                  </div>
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToOrder(item)">Order</span>
                  </div>
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="deleteOrder(item)">Delete</span>
                  </div>
                </div>

              </div>
            </v-menu>
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Investment Status</div>
            {{ item.fancy_status }}
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Valid Until</div>
            {{ item.project.maturity_date }}
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Target IRR</div>
            {{ item.project.irr }} %
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Opportunity Status</div>
            {{item.project.fancy_status}}
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Amount</div>
            <span v-if="item.amount_pretty">

              <v-icon v-if="item.project.currency">{{ item.project.currency.icon }}</v-icon>
              {{ item.amount_pretty }}

            </span>
            <span v-if="!item.amount_pretty">-</span>
          </div>
        </div>

        <div class="black-table-row portfolio-row-no-items" v-if="!allItems.length">

          <v-icon>mdi-alert-circle-outline</v-icon>
          This section displays your activity

        </div>

      </div>


      <div class="black-table" v-if="activeTab === 'eoi'">

        <div class="black-table-row" v-for="item in interestOrders" v-bind:key="item.id">
          <div class="black-table-column table-portfolio-project-name position-relative black-table-bottom-border">
            <div class="table-project-logo">
              <img v-bind:src="item.project.logo_public_path" alt="">
            </div>
            <span v-bind:title="item.project.name">{{ item.project.name }}</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">

                <div class="portfolio-table-item-button">
                  <v-icon v-bind="attrs"
                          v-on="on">mdi-dots-vertical
                  </v-icon>
                </div>

              </template>
              <div class="portfolio-table-item-dropdown-holder">
                <div class="portfolio-table-item-dropdown-content">
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToProject(item)">Opportunity</span>
                  </div>
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToOrder(item)">Order</span>
                  </div>
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="deleteOrder(item)">Delete</span>
                  </div>
                </div>

              </div>
            </v-menu>
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Instrument</div>
            {{ item.project.instrument_type }}
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Valid Until</div>
            {{ item.project.maturity_date }}
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Target IRR</div>
            {{ item.project.irr }} %
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Opportunity Status</div>
            <span
                v-if="item.project.amount_collected > item.project.minimum_amount_to_collect && item.project.minimum_amount_to_collect">
              Unlim. Fundraising
            </span>

            <span
                v-if="!(item.project.amount_collected > item.project.minimum_amount_to_collect && item.project.minimum_amount_to_collect)">{{
                item.project.fancy_status
              }}</span>

          </div>
        </div>

        <div class="black-table-row portfolio-row-no-items" v-if="!interestOrders.length">

          <v-icon>mdi-alert-circle-outline</v-icon>
          This section displays your expression of interest

        </div>

      </div>


      <div class="black-table" v-if="activeTab === 'preorder'">

        <div class="black-table-row" v-for="item in preorderOrders" v-bind:key="item.id">
          <div class="black-table-column table-portfolio-project-name position-relative black-table-bottom-border">
            <div class="table-project-logo">
              <img v-bind:src="item.project.logo_public_path" alt="">
            </div>
            <span v-bind:title="item.project.name">{{ item.project.name }}</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">

                <div class="portfolio-table-item-button">
                  <v-icon v-bind="attrs"
                          v-on="on">mdi-dots-vertical
                  </v-icon>
                </div>

              </template>
              <div class="portfolio-table-item-dropdown-holder">
                <div class="portfolio-table-item-dropdown-content">
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToProject(item)">Opportunity</span>
                  </div>
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToOrder(item)">Order</span>
                  </div>
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="deleteOrder(item)">Delete</span>
                  </div>
                </div>

              </div>
            </v-menu>
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Instrument</div>
            {{ item.project.instrument_type }}
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Valid Until</div>
            {{ item.project.maturity_date }}
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Target IRR</div>
            {{ item.project.irr }} %
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Opportunity Status</div>
            <span
                v-if="item.project.amount_collected > item.project.minimum_amount_to_collect && item.project.minimum_amount_to_collect">
              Unlim. Fundraising
            </span>

            <span
                v-if="!(item.project.amount_collected > item.project.minimum_amount_to_collect && item.project.minimum_amount_to_collect)">{{
                item.project.fancy_status
              }}</span>

          </div>
        </div>

        <div class="black-table-row portfolio-row-no-items" v-if="!preorderOrders.length">

          <v-icon>mdi-alert-circle-outline</v-icon>
          This section displays your pre-orders

        </div>

      </div>


      <div class="black-table" v-if="activeTab === 'subscribed'">

        <div class="black-table-row" v-for="item in pendingOrders" v-bind:key="item.id">
          <div class="black-table-column table-portfolio-project-name position-relative black-table-bottom-border">
            <div class="table-project-logo">
              <img v-bind:src="item.project.logo_public_path" alt="">
            </div>
            <span v-bind:title="item.project.name">{{ item.project.name }}</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">

                <div class="portfolio-table-item-button">
                  <v-icon v-bind="attrs"
                          v-on="on">mdi-dots-vertical
                  </v-icon>
                </div>

              </template>
              <div class="portfolio-table-item-dropdown-holder">
                <div class="portfolio-table-item-dropdown-content">
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToProject(item)">Opportunity</span>
                  </div>
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToOrder(item)">Order</span>
                  </div>
                </div>

              </div>
            </v-menu>
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Order</div>
            {{ item.reference }}
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Valid Until</div>
            {{ item.project.maturity_date }}
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Amount</div>
            <div>
              <v-icon v-if="item.project.currency">{{ item.project.currency.icon }}</v-icon>
              {{ item.amount_pretty }}
            </div>
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Payment Status</div>
            <div class="text-right">
              <v-icon style="color: red">mdi-alert</v-icon>
              Waiting for payment
            </div>
          </div>
        </div>

        <div class="black-table-row portfolio-row-no-items" v-if="!pendingOrders.length">

          <v-icon>mdi-alert-circle-outline</v-icon>
          This section displays your orders

        </div>

      </div>


      <div class="black-table" v-if="activeTab === 'invested'">

        <div class="black-table-row" v-for="item in executedTransactions" v-bind:key="item.id">
          <div class="black-table-column table-portfolio-project-name position-relative black-table-bottom-border">
            <div class="table-project-logo">
              <img v-bind:src="item.project.logo_public_path" alt="">
            </div>

            <span v-bind:title="item.project.name">{{ item.project.name }}</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">

                <div class="portfolio-table-item-button">
                  <v-icon v-bind="attrs"
                          v-on="on">mdi-dots-vertical
                  </v-icon>
                </div>

              </template>
              <div class="portfolio-table-item-dropdown-holder">
                <div class="portfolio-table-item-dropdown-content">
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToProject(item)">Opportunity</span>
                  </div>
                </div>

              </div>
            </v-menu>
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Instrument</div>
            {{ item.project.instrument_type }}
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Expected Maturity</div>
            {{ item.project.maturity_date }}
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Amount</div>
            <div>
              <v-icon v-if="item.project.currency">{{ item.project.currency.icon }}</v-icon>
              {{ item.amount_pretty }}
            </div>
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Opportunity Status</div>
            <span
                v-if="item.project.amount_collected > item.project.minimum_amount_to_collect && item.project.minimum_amount_to_collect">
              Unlim. Fundraising
            </span>

            <span
                v-if="!(item.project.amount_collected > item.project.minimum_amount_to_collect && item.project.minimum_amount_to_collect)">{{
                item.project.fancy_status
              }}</span>

          </div>
        </div>

        <div class="black-table-row portfolio-row-no-items" v-if="!executedTransactions.length">

          <v-icon>mdi-alert-circle-outline</v-icon>
          This section displays your investments

        </div>

      </div>


      <div class="black-table" v-if="activeTab === 'exited'">

        <div class="black-table-row" v-for="item in exitedTransactions" v-bind:key="item.id">
          <div class="black-table-column table-portfolio-project-name position-relative black-table-bottom-border">
            <div class="table-project-logo">
              <img v-bind:src="item.project.logo_public_path" alt="">
            </div>
            <span v-bind:title="item.project.name">{{ item.project.name }}</span>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">

                <div class="portfolio-table-item-button">
                  <v-icon v-bind="attrs"
                          v-on="on">mdi-dots-vertical
                  </v-icon>
                </div>

              </template>
              <div class="portfolio-table-item-dropdown-holder">
                <div class="portfolio-table-item-dropdown-content">
                  <div class="portfolio-table-item-dropdown-item">
                    <span v-on:click="goToProject(item)">Opportunity</span>
                  </div>
                </div>

              </div>
            </v-menu>
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Instrument</div>
            {{ item.project.instrument_type }}
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Matured</div>
            {{ item.project.maturity_date }}
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>Amount</div>
            <div>
              <v-icon v-if="item.project.currency">{{ item.project.currency.icon }}</v-icon>
              {{ item.amount_pretty }}
            </div>
          </div>
          <div class="black-table-column display-flex flex-space-between">
            <div>P&L</div>
            <div>
              <v-icon v-if="item.project.currency">{{ item.project.currency.icon }}</v-icon>
              {{ item.profit_pretty }}
            </div>
          </div>
        </div>

        <div class="black-table-row portfolio-row-no-items" v-if="!exitedTransactions.length">

          <v-icon>mdi-alert-circle-outline</v-icon>
          The section displays completed projects with your investments

        </div>

      </div>

    </div>
    <!-- Mobile template end -->

  </div>
</template>


<style lang="scss">

.black-table-tab-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 12px;
}

.black-table-tab {
  cursor: pointer;

  &:hover {
    opacity: .7;
  }

  &.active {
    font-weight: bold;
  }
}

.table-project-logo {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  float: left;
  margin-right: 10px;

  img {
    width: 30px;
    height: 30px;
  }
}

.portfolio-page-table-header {
  text-align: left;
  margin-bottom: 16px;
  margin-top: 36px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height, or 125% */

  color: #FFFFFF;
}

.black-table-column {
  flex: 1
}

.black-table-column-header {
  font-weight: bold;
}

.table-portfolio-project-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 2;
}

.portfolio-page-header {

  text-align: left;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 18px;
  /* or 74% */


  color: #FFFFFF;
}

.portfolio-row-no-items {
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: center;

  i {
    margin-right: 14px;
  }
}

.portfolio-table-item-button {
  position: absolute;
  right: 10px;
  top: 10px;
}

.portfolio-table-item-dropdown-content {
  background: #14191D;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.portfolio-table-item-dropdown-item {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */
  padding: 4px 8px;

  text-align: center;
  cursor: pointer;

  color: #FFFFFF;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}


@media screen and (max-width: 600px) {

  .portfolio-mobile {

    .black-table {
      .black-table-row {
        flex-direction: column;
      }
    }

  }

}


</style>


<script>

import moment from 'moment';

import userRepository from "@/repositories/userRepository";
import transactionRepository from "@/repositories/transactionRepository";
import numbersFormatter from "@/helpers/numbersFormatter";
import orderRepository from "@/repositories/orderRepository";


export default {
  name: 'PortfolioPage',

  data: () => ({
    user: {},
    allItems: [],
    preorderOrders: [],
    exitedTransactions: [],
    executedTransactions: [],
    interestOrders: [],
    pendingOrders: [],
    activeTab: 'all'
  }),

  computed: {},

  methods: {

    getTransactions() {

      transactionRepository.getList().then((response) => {

        this.allItems = []

        this.executedTransactions = response.data.results.filter((item) => {
          return item.status === 'executed'
        });

        this.executedTransactions = this.executedTransactions.map((item) => {

          item.amount_pretty = numbersFormatter.numberWithSpaces(item.amount)


          return item
        })

        this.exitedTransactions = response.data.results.filter((item) => {
          return item.status === 'profit'
        });


        response.data.results.forEach((item) => {

          item.amount_pretty = numbersFormatter.numberWithSpaces(item.amount)
          item.profit_pretty = numbersFormatter.numberWithSpaces(item.profit)

          item._index = this.allItems.length

          if (item.project.status === 'in_progress') {

            let percent = item.project.amount_to_collect / 100

            let result = Math.floor(item.project.amount_collected / percent)

            if (result > 100) {
              result = 100
            }

            if (result < 0 || result == null || isNaN(result)) {
              result = 0
            }

            item.project.fancy_status = 'Fundraising (' + result + '%)'
          } else if (item.project.status === 'invested') {
            item.project.fancy_status = 'Invested'
          } else if (item.project.status === 'exited') {
            item.project.fancy_status = 'Exited'
          }else if (item.project.status === 'coming') {
            item.project.fancy_status = 'Coming'
          }

          if (item.status === 'executed') {
            item.fancy_status = 'Invested'
          } else if (item.status === 'profit') {
            item.fancy_status = 'Exited';
          }


          this.allItems.push(item)

        });

      })

      this.exitedTransactions = this.exitedTransactions.map((item) => {

        item.amount_pretty = numbersFormatter.numberWithSpaces(item.amount)
        item.profit_pretty = numbersFormatter.numberWithSpaces(item.profit)




        return item
      })


    },

    getUser() {
      userRepository.getMe().then((response) => {
        this.user = response.data.results[0];

        console.log('user', this.user)

        this.user.orders = this.user.orders.map((item) => {

          item.amount_pretty = numbersFormatter.numberWithSpaces(item.amount)

          if (item.project.status === 'in_progress') {

            let percent = item.project.amount_to_collect / 100

            let result = Math.floor(item.project.amount_collected / percent)

            if (result > 100) {
              result = 100
            }

            if (result < 0 || result == null || isNaN(result)) {
              result = 0
            }

            item.project.fancy_status = 'Fundraising (' + result + '%)'
          } else if (item.project.status === 'invested') {
            item.project.fancy_status = 'Invested'
          } else if (item.project.status === 'exited') {
            item.project.fancy_status = 'Exited'
          }else if (item.project.status === 'coming') {
            item.project.fancy_status = 'Coming'
          }

          item.valid_until_pretty = moment(new Date(item.valid_until)).format('DD MMM yyyy');

          return item

        })

        this.interestOrders = this.user.orders.filter((item) => {
          return item.status === 'interest' && !item.amount;
        })

        this.pendingOrders = this.user.orders.filter((item) => {
          return item.status === 'pending';
        })

        this.preorderOrders = this.user.orders.filter((item) => {
          return item.status === 'interest' && item.amount;
        })


        this.user.orders.forEach((item) => {

          if (item.status === 'interest' && !item.amount) {
            item.fancy_status = 'Expression of Interest'
          } else if (item.status === 'interest' && item.amount) {
            item.fancy_status = 'Pre-order'
          } else if (item.status === 'pending') {
            item.fancy_status = 'Subscribed'
          }

          item._index = this.allItems.length
          this.allItems.push(item)
        })


        console.log('this', this);

      })
    },

    goToProject(order) {
      this.$router.push('/project/' + order.project.id)
    },

    goToOrder(order) {
      this.$router.push('/order/' + order.id)
    },

    goToInterest(order){
      this.$router.push('/interest/' + order.id)
    },

    deleteOrder(order) {

      orderRepository.deleteById(order.id).then(() => {
        this.getUser();
        this.getTransactions();
      })

    },

    setActiveTab(tab) {
      this.activeTab = tab
    },

  },

  mounted() {

    this.getTransactions();
    this.getUser();

  }
}
</script>
