<template>
  <div class="profile-settings-page">
    <h1 class="profile-settings-page-header">Settings</h1>
    <div>

      <button class="fancy-button" v-on:click="enableTwoFactor()" v-if="!user.two_factor_verification">Enable Two Factor
        Authorization
      </button>
      <button class="fancy-button" v-on:click="disableTwoFactor()"
              v-if="user.two_factor_verification && !codeData.provisioning_uri">Disable Two
        Factor Authorization
      </button>

      <div v-show="codeData.provisioning_uri && !showSecurityCodeInput">

        <div class="profile-settings-two-factor-help">

          <p>
            An authenticator app lets you generate security codes on your mobile phone.
          </p>
          <p>
            To configure your authenticator app:
          </p>
          <ul>
            <li>Add a new time-based token.</li>
            <li>Use your app to scan the QR code below</li>
          </ul>

          <div class="profile-settings-two-factor-help-apps">
            <ul >
              <li style="margin-top: 16px">
                <div class="text-center">Google Authenticator</div>

                <div class="display-flex" style="margin-top: 16px">

                  <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                     class="text-link"
                     target="_blank">
                    <img alt='Get it on Google Play' src="../assets/google-play.png"/>
                  </a>

                  <a href="https://apps.apple.com/ru/app/google-authenticator/id388497605"
                     class="text-link"
                     target="_blank">
                    <img alt='Download on the app store' src="../assets/appstore.png" class="appstore-badge"/>
                  </a>

                </div>

              </li>

              <li style="margin-top: 16px">
                <div class="text-center">Microsoft Authenticator</div>

                <div class="display-flex" style="margin-top: 16px">

                  <a href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=ru"
                     class="text-link"
                     target="_blank">
                    <img alt='Get it on Google Play' src="../assets/google-play.png"/>
                  </a>

                  <a href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
                     class="text-link"
                     target="_blank">
                    <img alt='Download on the app store' src="../assets/appstore.png" class="appstore-badge" />
                  </a>
                </div>

              </li>
            </ul>

          </div>

        </div>

        <div class="code-holder"></div>

        <button class="simple-button" v-on:click="showSecurityCodeInput = true" style="margin-top: 16px;">Next</button>

      </div>

      <div v-if="showSecurityCodeInput">

        <v-text-field
            v-model="securityCode"
            label="Security Code"
            required
        ></v-text-field>

        <button class="fancy-button" v-on:click="activateSecurityCode()" :disabled="securityCode.length < 6">Activate
        </button>

        <div v-if="securityCodeErrorMessage" style="color: red; margin-top: 16px;">
          {{ securityCodeErrorMessage }}
        </div>

      </div>


    </div>
  </div>
</template>


<style lang="scss">

.profile-settings-page-header {
  text-align: left;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 16px;

}

.profile-settings-two-factor-help {
  text-align: left;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  margin-bottom: 16px;
}

.profile-settings-two-factor-help-apps {

  ul {
    list-style: none;
  }

  img {
    height: 100px;
  }

  .appstore-badge {
    height: 70px;
    margin-top: 15px;
  }
}

</style>

<script>

import userRepository from "@/repositories/userRepository";

import QRious from 'qrious'
import authRepository from "@/repositories/authRepository";

export default {
  name: 'ProfileSettingsPage',

  data: () => ({
    user: {},
    token: {},
    codeData: {},
    showSecurityCodeInput: false,
    securityCode: '',
    securityCodeErrorMessage: ''
  }),

  computed: {},

  methods: {

    generateQrCodeImage() {

      console.log('provisioning_uri', this.codeData.provisioning_uri);

      var qr = new QRious({
        element: document.querySelector('.code-holder'),
        value: this.codeData.provisioning_uri,
        size: 256
      });

      document.querySelector('.code-holder').append(qr.image)

    },

    enableTwoFactor() {

      this.user.two_factor_verification = true;

      userRepository.update(this.user.id, this.user).then((response) => {

        this.user = response.data

        userRepository.generateCode().then((response) => {

          this.codeData = response.data

          this.generateQrCodeImage();

        })

      })
    },

    disableTwoFactor() {

      this.user.two_factor_verification = false;

      userRepository.deleteOTP(this.user.otp_token.id).then(() => {

        userRepository.update(this.user.id, this.user).then((response) => {

          this.user = response.data;

        })

      })

    },

    activateOTP() {

      this.user.otp_token.is_activated = true;

      userRepository.updateOTP(this.user.otp_token.id, this.user.otp_token).then(() => {

        this.getMe();

      })

    },

    activateSecurityCode() {

      authRepository.validateCode({
        code: this.securityCode,
        email: this.user.email
      }).then((response) => {

        this.codeIsValid = response.data.match;

        if (this.codeIsValid) {

          userRepository.updateOTP(response.data.id, {is_activated: true, name: '-'}).then(() => {

            this.showSecurityCodeInput = false;
            this.codeData = {}
            this.getMe()

          })
        } else {

          this.securityCodeErrorMessage = 'Security code is wrong or expired'

        }

      })


    },

    getMe() {
      userRepository.getMe().then((response) => {
        this.user = response.data.results[0];

        console.log('user', this.user)
      })
    }

  },

  mounted() {

    this.getMe()

  }
}
</script>
