<template>
  <div class="header">

    <router-link to="/" ><img alt="Marscapital" src="../assets/logo_sis.png" class="header-logo"></router-link>
    <div class="header-nav-menu" v-if="$route.name !== 'LoginPage' && $route.name !== 'SignupPage' && isAuthorized">
      <router-link to="/" class="header-link hidden-sm-and-down">Opportunities</router-link>
      <router-link to="/portfolio" class="header-link hidden-sm-and-down">My Portfolio</router-link>


      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">

          <div class="header-notifications-holder">
            <v-icon v-bind="attrs"
                    v-on="on" v-if="!notifications.length">mdi-bell
            </v-icon>
            <v-badge v-if="notifications.length" color="pink"
                     dot
            >
              <v-icon v-bind="attrs"
                      v-on="on">
                mdi-bell
              </v-icon>
            </v-badge>
          </div>

        </template>
        <div class="header-notification-dropdown-holder">

          <div v-if="!notifications.length" class="header-notification-dropdown-item">
            Nothing Happened
          </div>

          <div v-for="item in notifications" v-bind:key="item.id" class="header-notification-dropdown-item">

            <div v-if="item.thumbnail">
              <img v-bind:src="item.thumbnail" alt="">
            </div>
            <div>
              <div class="header-notification-dropdown-item-title">
                {{ item.title }}
              </div>
              <div class="header-notification-dropdown-item-text">
                {{ item.text }}
              </div>

            </div>

          </div>

        </div>
      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">

          <div class="header-account-holder">
            <v-icon>mdi-account</v-icon>
            <v-icon v-bind="attrs"
                    v-on="on">mdi-chevron-down
            </v-icon>
          </div>

        </template>
        <div class="header-dropdown-holder">
          <div class="header-dropdown-content">
            <div class="header-dropdown-item">
              <router-link to="/profile" style="margin-top: 0">Profile</router-link>
            </div>
            <div class="header-dropdown-item">
              <router-link to="/profile/settings">Settings</router-link>
            </div>
            <div class="header-dropdown-item">
              <a href="#" v-on:click="logout()" class="dd-link pointer hover-bg-moon-gray">Logout</a>
            </div>
          </div>

        </div>
      </v-menu>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">

          <div class="mobile-menu-holder hidden-md-and-up">
            <v-icon v-bind="attrs"
                    v-on="on">mdi-menu
            </v-icon>

          </div>

        </template>
        <div class="header-dropdown-holder">
          <div class="header-dropdown-content">
            <div class="header-dropdown-item">
              <router-link to="/">Opportunities</router-link>
            </div>
            <div class="header-dropdown-item">
              <router-link to="/portfolio">My Portfolio</router-link>
            </div>
          </div>

        </div>
      </v-menu>

    </div>

  </div>
</template>

<style lang="scss">


.header {
  //width: 1280px;
  //margin: 0 auto;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.header-logo {
  width: 160px;
  margin-top: 14px;
}


.header-nav-menu {

  flex: 3;
  display: flex;
  justify-content: flex-end;
  position: relative;

  .header-link {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    display: block;
    margin-top: 25px;
    margin-right: 72px;
    /* identical to box height, or 150% */

    text-align: center;
    text-decoration: none;

    color: #FFFFFF;

    &:hover {
      opacity: .7;
    }

    &.router-link-exact-active {
      font-weight: bold;
    }
  }

}


.header-account-holder {
  padding-top: 25px;
}

.header-dropdown-holder {

  z-index: 1;

  width: 131px;
  padding: 8px 0;
  //height: 124px;

  background: #14191D;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  .header-dropdown-item {
    text-align: left;
  }

  .header-dropdown-item a {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    text-align: left;
    padding-left: 8px;
    /* identical to box height, or 167% */

    text-decoration: none;
    margin-top: 8px;
    display: block;

    color: #FFFFFF;


    &:hover {
      opacity: .7;
    }
  }
}

.header-notifications-holder {
  padding-top: 25px;
  margin-right: 36px;
}

.header-notification-dropdown-holder {
  width: 214px;
  //height: 124px;

  background: #14191D;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.header-notification-dropdown-item {
  display: flex;
  padding: 8px;

  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);

  &:last-child {
    border-bottom: none;
  }
}

.header-notification-dropdown-item-thumbnail {
  width: 30px;
  margin-right: 8px;
}

.header-notification-dropdown-item-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
}

.header-notification-dropdown-item-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
}

.mobile-menu-holder {
  padding-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
}


@media screen and (max-width: 600px) {

  .header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: #382040;
    z-index: 10;
    height: 60px;
  }

  .header-notifications-holder {
    padding-top: 15px;
  }
  .header-account-holder {
    padding-top: 15px;
  }


  .main-app {
    padding-top: 60px;
  }

  .header-logo {
    margin-left: 15px;
    margin-top: 5px;
    width: 130px;
    //height: 37px;
  }

}


</style>

<script>
import authRepository from "@/repositories/authRepository";
import notificationRepository from "@/repositories/notificationRepository";
import cookieService from "@/services/cookieService";

export default {
  name: 'Header',

  data: () => ({
    notifications: [],
    activator: false,
    isAuthorized: false
  }),

  methods: {

    logout() {

      authRepository.logout()

      cookieService.deleteCookie('access')
      cookieService.deleteCookie('refresh')

      this.$router.push('/login')

    },


    getNotifications() {

      notificationRepository.getList().then((response) => {

        this.notifications = response.data.results;

      })

    }

  },

  mounted() {

    if (this.$route.name !== 'LoginPage' && this.$route.name !== 'SignupPage') {

      this.getNotifications();
    }

    if (cookieService.getCookie('access')) {
      this.isAuthorized = true
    }

  },
}
</script>
