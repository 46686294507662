<template>
  <div class="privacy-policy-page">
    <h1 class="privacy-policy-header">Privacy Policy</h1>

    <p>
      MARS Capital S.A. (the “Platform operator”) is dedicated to protecting the confidentiality and
      privacy of information entrusted to it and is the responsible party for the processing of personal
      data collected through use of the website https://sis.mars-cap.com//, the information platform
      provided through the website (the “Platform”) and the services of Platform operator as per the
      applicable data protection laws including in particular the Swiss Federal Act on Data Protection
      (FADP).
    </p>
    <p>
      The Platform is operated by the Platform operator registered at 2, COurs de Rive, 1204 Geneva,
      Switzerland.
    </p>
    <p>In the event you have any questions or would like to contact the Platform operator in connection
      with the platform please contact ir@mars-cap.com.
    </p>
    <p>
      The responsible data protection supervisory authority in Switzerland is Federal Data Protection and
      Information Commissioner.
    </p>
    <h3>1. Collection of personal data.</h3>
    <p>The Platform operator obtains personal data directly from users of the platform and its clients
      (collectively, “Users”) in a variety of ways, including obtaining personal data from the Users who
      complete online forms, subscribe to newsletters, attend meetings or use software applications.
      Platform operator may obtain the following categories of personal data about the Users:
    </p>
    <ul>
      <li>
        Contact details (including name, company name, work and mobile telephone numbers,
        work and personal email and postal address);
      </li>
      <li>
        Financial information (e.g. investment interests);
      </li>
      <li>
        Location-based data (e.g. geographical location of the User).
      </li>
    </ul>
    <p>
      In some cases the Platform uses cookies and other technologies to automatically collect certain
      types of information in the course of the User’s visit of the relevant web-sites. The collection of this
      information allows us to customize your online experience, improve the performance, usability and
      effectiveness of the platform. Where cookies are used, a statement will be sent to your browser
      explaining the use of cookies.
    </p>
    <h3></h3>

    <h3>2. Legal reasons of the collection and purpose of the processing of the personal data.</h3>
    <p>
      Platform operator may rely on the following lawful reasons for the collection and use personal
      data:
    </p>
    <ul>
      <li>
        Contract – the Platform operator may process personal data due to requirements of a
        contract.
      </li>
      <li>
        Consent – the Platform operator may rely on the consent of the user.
      </li>
      <li>
        Legitimate interests – Platform operator may rely on legitimate interests, including interest
        in delivering high-quality services and marketing.
      </li>
      <li>
        Legal obligations and public interest.
      </li>
    </ul>

    <p>
      The personal data is processed for the purposes of:
    </p>
    -provision and development of products, services, including through the platform, and website;<br/>
    -marketing of services;<br/>
    -evaluation of opportunities and personalizing of information to be provided to the Users;<br/>
    -promoting professional services, products and capabilities which may be of interest to the Users;<br/>
    -sending of information notes and providing access specific products and events;<br/>
    -administering, maintaining, developing and ensuring the security and functionality of the
    platform, applications and websites;<br/>
    -authenticating registered Users;<br/>
    -processing online requests, including responding to communications from the Users;<br/>
    -complying with legal and regulatory obligations relating to sanctions, embargo assessment,
    countering money laundering, terrorist financing, fraud and other forms of financial crime;<br/>
    -preventing fraud or criminal activity, safeguarding the IT systems and handling of claims.
    <p>
      It should be noted that in cases where the Platform operator processes personal data based on
      a statutory or contractual requirement, the refusal by the User to produce his/her data may lead
      to termination of use of platform or services.
    </p>
    <h3>3. Categories of recipients and sharing of personal data with third parties</h3>
    <p>The personal information may be available to</p>
    - the Platform operator’s affiliated legal entities and sub-contractors, who may need to have
    access to such information in connection with operation of the Platform;<br/>
    - Parties that support the Platform and the services (including providers of telecommunication
    systems, IT system support, archiving services, and cloud-based software services, data storage
    services and hosting providers, CRM systems);<br/>
    - professional advisers, including lawyers, auditors and insurers;<br/>
    - payment services providers;<br/>
    - marketing services providers;<br/>
    - government and regulatory agencies or to other third parties as required in accordance with
    applicable law or regulation.

    <h3>4. Countries where personal data is stored</h3>

    <p>We store personal data on servers located in Switzerland. We may transfer personal data to other
      permitted recipients as stated in section 3 above and such transfer may result in storage of
      personal data in other countries that are deemed to provide an adequate level of protection
      according to lists of countries published by the Federal Data Protection and Information
      Commissioner, including countries of the European Union.
    </p>
    <p>
      Each recipient organization is required to safeguard personal data in accordance with the
      contractual obligations and applicable data protection laws. Such safeguards may include
      applying data protection model clauses, binding corporate rules or other standard contractual
      obligations which provide for appropriate protection of data.
    </p>
    <h3>5. User’s data protection rights</h3>
    <p>The Platform operator processes personal information about Users and Users have the rights listed
      below.</p>
    <ul>
      <li>Access – User can request to verify whether the Platform operator is processing his
        personal data, and if so, to provide more specific information.</li>
      <li>
        Correction – User can request to correct Platform operator’s records believed to contain
        incorrect or incomplete information about him.
      </li>
      <li>
        Erasure – the User can request us to erase his personal data after withdraw his consent to
        processing or when the Platform operator no longer needs it for the purpose it was
        originally collected and no retention requirements exist.
      </li>
      <li>
        Processing restrictions – the User can ask to temporarily restrict processing of his personal
        data.
      </li>
      <li>
        Data portability – the User can ask us to transmit his personal data in electronic form if
        technically feasible.
      </li>
      <li>
        Automated Individual Decision-making – User can request to review any decisions made
        about him, which we made solely based on automated processing, including profiling.
      </li>
      <li>
        Right to Object to Marketing including Profiling – the User can object to our use of your
        personal data for marketing purposes, including profiling.
      </li>
      <li>
        Right to Object to Active Sourcing – You can object to our use of your personal data for
        active sourcing purposes.
      </li>
      <li>
        Right to Withdraw Consent – the user can withdraw consent previously given to one or
        more specified purposes of processing of personal data. It may mean the User will not be
        able to receive certain products or services;
      </li>
      <li>
        Right to file a complaint with a regulatory authority.
      </li>
    </ul>
    <p>To submit a data request please direct your correspondence to: ir@mars-cap.com</p>
    <p>The Platform operator aims to respond within 30 days from the date of receipt of privacy-related
    communications.</p>
    <p>No fee is required to make a request unless the request is clearly excessive. Depending on the
    circumstances, the Platform operator may be unable to comply with the request based on other
    lawful grounds.</p>
    <h3>6. Personal data security</h3>
    <p>Appropriate technical and organisational security procedures are in place to protect personal
    data (including sensitive personal data) from loss, misuse, alteration or destruction. Individuals and
    entities who have access to the data are legally bound to maintain the confidentiality of such
    information.</p>
    <p>The User remains responsible for keeping user ID and password confidential.</p>
    <p>The transmission of data via the Internet is not completely secure and The Platform operator
    cannot ensure or guarantee the security of your data transmitted to the platform; any transmission
    is at the User’s own risk.</p>
    <h3>7. Data retention period</h3>
    <p>The Platform operator retains personal data for so long as the personal data is needed for the
      purposes for which it was collected or in line with legal and regulatory requirements or contractual
      arrangements.</p>
    <h3>8. Changes to privacy policy</h3>
    <p>The Platform operator regularly reviews this Privacy Policy and will post any updates to it on
      https://sis.mars-cap.com/webpage. This Privacy Policy was last updated on 31 March 2022.
    </p>

  </div>
</template>


<style lang="scss">

</style>

<script>

export default {
  name: 'PrivacyPolicyPage',

  data: () => ({}),

  computed: {},

  methods: {

    // File upload logic start

  },
  mounted() {


  }
}
</script>
