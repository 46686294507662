<template>
  <div class="project-update-page" >


    <div class="project-update-page-header-holder">
     <h2 class="project-update-page-header">{{update.title}}</h2>
     <h2 class="project-update-page-date">{{update.date}}</h2>
    </div>

    <div class="project-update-page-content">

      <div v-if="update.thumbnail_public_path"  class="project-update-page-thumbnail">
        <img class="project-update-thumbnail" v-bind:src="update.thumbnail_public_path" alt="">
      </div>


      <div v-html="update.content">

      </div>

    </div>


  </div>
</template>

<style lang="scss">

.project-update-page {

  .project-update-page-header-holder {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .project-update-page-thumbnail {
    float: left;
    margin-right: 40px;
  }

}

</style>

<script>


import projectRepository from "@/repositories/projectRepository";

export default {
  name: 'ProjectUpdatePage',

  data: () => ({
    update: {

    },
  }),

  methods: {

    getData() {

      projectRepository.getProjectUpdateById(this.$route.params.id, this.$route.params.updateId).then((response) => {

        this.update = response.data

        console.log('update', this.update)

      })

    }

  },
  mounted() {

    this.getData();

  }

}
</script>
