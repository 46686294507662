import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import LoginPage from '../views/LoginPage.vue'
import LogoutPage from '../views/LogoutPage.vue'
import SignupPage from '../views/SignupPage.vue'
import ProfilePage from '../views/ProfilePage.vue'
import ProfileSettingsPage from '../views/ProfileSettingsPage.vue'
import PortfolioPage from '../views/PortfolioPage.vue'
import OrderPage from '../views/OrderPage.vue'
import ProjectInterestPage from '../views/ProjectInterestPage.vue'
import ProjectUpdatePage from '../views/ProjectUpdatePage.vue'
import ProjectsListPage from '../views/ProjectsListPage.vue'
import ProjectPage from '../views/ProjectPage.vue'
import NotFoundPage from '../views/NotFoundPage.vue'
import PermissionDeniedPage from '../views/PermissionDeniedPage.vue'
import PrivacyPolicyPage from '../views/PrivacyPolicyPage.vue'
import NdaPage from '../views/NdaPage.vue'
import cookieService from "@/services/cookieService";
import ForgotPasswordPage from "@/views/ForgotPasswordPage";

Vue.use(VueRouter)

const routes = [

  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/logout',
    name: 'LogoutPage',
    component: LogoutPage
  },
  {
    path: '/signup',
    name: 'SignupPage',
    component: SignupPage
  },
  {
    path: '/forgot-password',
    name: 'ForgotPasswordPage',
    component: ForgotPasswordPage
  },


  {
    path: '/profile',
    name: 'ProfilePage',
    component: ProfilePage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/settings',
    name: 'ProfileSettingsPage',
    component: ProfileSettingsPage,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/portfolio',
    name: 'PortfolioPage',
    component: PortfolioPage,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/',
    name: 'ProjectsListPage',
    component: ProjectsListPage,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/project/:id',
    name: 'ProjectPage',
    component: ProjectPage,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/order/:id',
    name: 'OrderPage',
    component: OrderPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/interest/:id',
    name: 'ProjectInterestPage',
    component: ProjectInterestPage,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/project/:id/update/:updateId',
    name: 'ProjectUpdatePage',
    component: ProjectUpdatePage,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/privacy-policy',
    name: 'PrivacyPolicyPage',
    component: PrivacyPolicyPage
  },

  {
    path: '/nda',
    name: 'NdaPage',
    component: NdaPage
  },

  { path: "/403", component: PermissionDeniedPage},
  { path: "/404", component: NotFoundPage },
  { path: "*", component: NotFoundPage }

  // //  Deprecated
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!cookieService.getCookie('access')) {
      next({ name: 'LoginPage' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
