<template>
  <div class="forgot-password">

    <img alt="Marscapital" src="../assets/logo_sis.png" class="header-logo">

    <div class="display-flex flex-space-between forgot-password-holder">
      <div class="forgot-password-form-wrapper flex-1">
        <div class="forgot-password-form-wrapper-inner-holder">

          <div v-if="state === 'default'">
            <h1 class="text-center">Forgot Password</h1>

            <p class="text-center">Don't worry! Just fill in your email and we'll send you a link to reset your
              password.</p>

            <div>

              <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
              >
                <v-text-field
                    v-model="email"
                    label="Email"
                    :rules="emailRules"
                    required
                ></v-text-field>

                <button v-on:click="initPasswordReset($event)"
                        v-bind:class="{'disabled-button': !email}"
                        class="fancy-button forgot-password-button"

                >Send password reset email
                </button>

              </v-form>

              <div style="margin-top: 24px;" class="form-bottom-text">
                Return to
                <router-link to="/login">Log in</router-link>
              </div>

            </div>
          </div>

          <div v-if="state === 'sent'">

            <h1>Your password reset email has been sent!</h1>

            <p>
              We have sent a password reset email to your email address:
              <b>{{email}}</b>.
            </p>

            <p>
              Please check your inbox to continue.
            </p>

          </div>

          <div v-if="state === 'restore'">

            <h1 class="text-center">Restore Password</h1>

            <div>

              <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
              >
                <v-text-field

                    v-model="password"
                    label="Password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                    :rules="passwordRules"
                    required
                ></v-text-field>

                <button v-on:click="passwordReset($event)"
                        class="fancy-button forgot-password-button"

                >Confirm
                </button>

              </v-form>

              <div style="margin-top: 24px;" class="form-bottom-text">
                Return to
                <router-link to="/login">Log in</router-link>
              </div>

            </div>

          </div>

          <div v-if="state === 'success'">

            <h1>Success</h1>

            <p>
              You have successfully reset your password!
            </p>

            <div style="margin-top: 24px;" class="form-bottom-text">
              Return to
              <router-link to="/login">Log in</router-link>
            </div>

          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<style lang="scss">

.forgot-password {

  .header-logo {
    width: 160px;
    margin-left: 16px;
  }


  min-height: 100%;
  padding-bottom: 64px;


  .forgot-password-button {
    margin: 0 auto;
    display: block;
    text-transform: capitalize;
    padding: 8px 16px;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .form-bottom-text {
    text-align: center;

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .forgot-password-form-wrapper-inner-holder {
    width: 50%;
    margin: 200px auto 0;

    h1 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 30px;
      margin-bottom: 40px;
    }
  }


}


@media screen and (max-width: 600px) {

  .forgot-password {

    background: none;

    .forgot-password-holder {
      flex-direction: column;
    }

    .forgot-password-marketing-text-inner-holder h1 {
      font-size: 63px;
      line-height: 63px;
    }

    .forgot-password-form-wrapper-inner-holder {
      width: 90%;
    }

    .forgot-password-marketing-text-inner-holder {
      width: 90%;
    }
  }

}

</style>

<script>
import authRepository from "@/repositories/authRepository";

export default {
  name: 'LoginPage',

  data: () => ({
    email: null,
    password: null,
    valid: true,
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    passwordRules: [
      v => !!v || 'Password is required',
      v => v.length >= 8 || 'Your password must contain at least 8 characters.',
    ],
    state: 'default',
    show: false,
  }),

  methods: {

    initPasswordReset(event) {

      event.preventDefault()

      authRepository.initPasswordReset({
        email: this.email
      }).then((response) => {

        console.log('login response', response)

        if (response.status === 200) {

          this.state = 'sent'

        }

      })

    },
    passwordReset(event){

      event.preventDefault()

      authRepository.passwordReset({
        token: this.token,
        password: this.password
      }).then((response) => {

        console.log('login response', response)

        if (response.status === 200) {

          this.state = 'success'

        }

      })

    }

  },
  mounted() {

    if (this.$route.query.token) {
      this.token = this.$route.query.token
      this.state = 'restore'

    }

    console.log('this.$route.query', this.$route.query)
    console.log('this.state', this.state)

  }
}
</script>
