<template>
  <div id="app">
    <v-app>

      <div class="app-layout main-app"
           v-if="$route.name !== 'LoginPage' && $route.name !== 'SignupPage' && $route.name !== 'ForgotPasswordPage'">

        <v-container>
          <Header/>
          <div class="app-body">
            <router-view/>
          </div>
        </v-container>

        <Footer/>

      </div>

      <div class="app-layout"
           v-if="$route.name === 'LoginPage' || $route.name === 'SignupPage' || $route.name === 'ForgotPasswordPage'">

        <router-view/>

      </div>


    </v-app>

  </div>


</template>

<style lang="scss">

.grecaptcha-badge {
  visibility: hidden;
}


@font-face {
  font-family: Montserrat;
  src: url('./assets/montserrat/static/Montserrat-Regular.ttf');
}

.app-body {
  padding-top: 35px;
}

.app-layout > .container {
  flex: 1 0 auto;
}


.v-application a {
  text-decoration: none;

  &:hover {
    opacity: .7;
  }
}

.fancy-button {
  background: linear-gradient(180deg, #BA4036 0%, #482E48 100%);
  border-radius: 10px;
  padding: 8px 24px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;

  color: #FFFFFF;
  text-align: center;

  &:hover {
    opacity: .7;
  }
}

.simple-button {
  background: #614C6B;
  border-radius: 10px;
  padding: 8px 24px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;

  color: #FFFFFF;
  text-align: center;

  &:hover {
    opacity: .7;
  }
}

body {
  text-align: left;
}

div.theme--dark.v-application {
  background: #382040;
}

#app {
  font-family: Montserrat, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

.app-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-body {
  flex: 1 0 auto;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}


.display-flex {
  display: flex;
}

.black-table {
  //background: #14191D;
  //box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #FFFFFF;

  text-align: left;

  border: 1px solid rgba(255, 255, 255, 0.7);

  .black-table-bottom-border {
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  }

  .black-table-header-row {
    background: #C4C4C433;

    display: flex;
    flex-direction: row;

    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    background: #C4C4C433;
  }

  .black-table-row {

    display: flex;
    flex-direction: row;

    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    background: #C4C4C433;

    &:nth-child(2n) {
      background: #382040;
    }

    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }

  .black-table-column {
    padding: 15px;
  }

  .black-table-column.flex-1 {
    flex: 1;
  }

  .black-table-column.flex-2 {
    flex: 2;
  }

  .black-table-column.flex-3 {
    flex: 3;
  }


}


@media screen and (max-width: 960px) {

  .portfolio-mobile {



    .black-table {

      .table-portfolio-project-name {
        background: #C4C4C433;
      }

      .black-table-row {
        background: #382040;
        flex-direction: column;
      }
    }

  }

}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.black-form-header {
  background: #2B3033;
  border-radius: 5px 5px 0px 0px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 19px;
  /* identical to box height, or 79% */
  padding: 21px 16px;
  text-align: left;

  color: #FFFFFF;
}

.black-form {
  background: #14191D;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 16px 0;

  .v-input {
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  .black-form-row {
    display: flex;
  }
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.disabled-button {
  opacity: .7;
  pointer-events: none;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-align-content-center {
  align-content: center;
}

@media (min-width: 1264px) {

  .container {
    max-width: 1440px;
  }

}
</style>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  components: {Header, Footer},

  metaInfo: {
    title: 'Smart Investment Space',
    meta: [
      {charset: 'utf-8'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'}
    ]
  },

  mounted() {

    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=6LeSw84eAAAAAEH9fM14GBjXJ2OTSfSpld3B5QDQ')
    document.head.appendChild(recaptchaScript)

    // 6LdhLM0eAAAAAEtUYCoIfjyqoQqsGdW_lg27u0AY

  }

}
</script>