<template>
  <div class="footer">
    <v-container>
      <div class="footer-wrapper flex-column flex-sm-column flex-md-row">
        <div class="flex-row d-flex">
          <img alt="Marscapital" src="../assets/logo.png" class="footer-logo">

          <div class="footer-copyright-text">
            © 2007-2022 MARS Capital S.A.
            All rights reserved
          </div>
        </div>

        <div class="footer-phone">
          <a href="tel:+41227161440<">+ 41 22 716 14 40</a>
        </div>

        <div class="footer-email">
          <a href="mailto:ir@mars-cap.com">ir@mars-cap.com</a>
        </div>

        <div class="footer-contacts-text">
          MARS Capital S.A.
          2, Cours de Rive, CH-1204,
          Geneva, Switzerland
        </div>


      </div>
    </v-container>
  </div>
</template>

<style lang="scss">

.footer {
  background: #2B1134;
  width: 100%;
  min-height: 96px;
  flex-shrink: 0;

}

.footer-wrapper {
  //width: 1280px;
  //margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.footer-logo {
  width: 75px;
  height: 75px;
  margin-top: 10px;
}

.footer-copyright-text {
  margin-left: 28px;
  margin-top: 34px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  line-height: 15px;

  width: 188px;
  height: 30px;

  color: #FFFFFF;
}

.footer-contacts-text {

  margin-top: 19px;
  width: 156px;
  height: 57px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;

  color: #FFFFFF;
}

.v-application {
  .footer-phone {

    margin-top: 38px;

    a {

      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
    }
  }
}

.v-application {
  .footer-email {

    margin-top: 38px;


    a {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
      text-decoration: none;

    }

  }

}

</style>
<script>

export default {
  name: 'Footer',

  data: () => ({}),

  methods: {}
}
</script>
